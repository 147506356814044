export function getExtention(name) {
  return name
    .slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1)
    .toUpperCase();
}

export function createPreviewImgSrc(src) {
  if (!isBlobURL(src)) src;

  return `${src}?${+(+new Date())}`;
}

export function isBlobURL(value) {
  const REGEXP_BLOB_URL = /^blob:.+\/[\w-]{36,}(?:#.+)?$/;
  return REGEXP_BLOB_URL.test(value);
}
