/*eslint no-unused-vars: 0*/

import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import History from "./history";
import { uuid4 } from "./utils";
import Select from "./select";
import Pencil from "./pencil";
import Line from "./line";
import Arrow from "./arrow";
import Rectangle from "./rectangle";
import Circle from "./circle";
import Pan from "./pan";
import IText from "./itext";
import Erase from "./erase";
import Tool from "./tools";
import forEach from "lodash/forEach";
import isEqual from "lodash/isEqual";

const fabric = require("fabric").fabric;

function isDataURL(s) {
  return !!s.match(isDataURL.regex);
}
isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
  };

  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 25,
    tool: Tool.Select,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false,
  };

  state = {
    parentWidth: 550,
    action: true,
    canvasScale: 1,
    SCALE_FACTOR: 1.1,
    sendJSON: true,
  };
  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas);
    this._tools[Tool.Circle] = new Circle(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.IText] = new IText(fabricCanvas);
    this._tools[Tool.Erase] = new Erase(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = (dataUrl, options = {}) => {
    let canvas = this._fc;
    fabric.Image.fromURL(dataUrl, (oImg) => {
      let opts = {
        left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
        top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
        scale: 0.5,
      };
      Object.assign(opts, options);
      oImg.scale(opts.scale);
      oImg.set({
        left: opts.left,
        top: opts.top,
      });
      canvas.add(oImg);
    });
  };

  /**
   * Action when an object is added to the canvas
   */
  _onObjectAdded = (e) => {
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    let obj = e.target;
    obj.__version = 1;
    obj.createdBy = obj.createdBy || `${this.props.createdBy}(Student)`;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);

    if (
      this.props.tool !== "line" &&
      this.props.tool !== "erase" &&
      this.props.tool !== "itext" &&
      this.props.tool !== "rectangle" &&
      this.props.tool !== "arrow" &&
      this.props.tool !== "circle"
    ) {
      if (this.state.sendJSON) {
        this.setState({ sendJSON: false }, () => {
          this.props.canvasToImage();
          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
        });
      }
    }
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = (e) => {};

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {};

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {};

  _onObjectModified = (e) => {
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
    this.props.canvasObjectModified("drawingModifiedByStudent");
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    let obj = e.target;
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;

    this.props.canvasObjectModified("drawingModifiedByStudent");
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    this._selectedTool.doMouseDown(e);
  };

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  _onMouseMove = (e) => {
    clearInterval(this.myTimer);
    this.manageSelectToolTimer();
    this._selectedTool.doMouseMove(e);
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  _onMouseUp = (e) => {
    this._selectedTool.doMouseUp(e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);

      if (this.state.sendJSON) {
        this.setState({ sendJSON: false }, () => {
          if (
            this.props.tool === "line" ||
            this.props.tool === "erase" ||
            this.props.tool === "itext" ||
            this.props.tool === "rectangle" ||
            this.props.tool === "arrow" ||
            this.props.tool === "circle"
          ) {
            this.props.canvasObjectModified("drawingModifiedByStudent");
          } else {
            this.props.canvasToImage();
          }

          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
        });
      }
    }
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { widthCorrection, heightCorrection } = this.props;
    const canvas = this._fc;
    const domNode = ReactDOM.findDOMNode(this);
    const { offsetWidth, clientHeight } = domNode;
    const prevWidth = canvas.getWidth();
    const prevHeight = canvas.getHeight();
    const wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    const hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      const bi = canvas.backgroundImage;
      bi.width *= wfactor;
      bi.height *= hfactor;
    }
    const objects = canvas.getObjects();
    for (const i in objects) {
      const obj = objects[i];
      const scaleX = obj.scaleX;
      const scaleY = obj.scaleY;
      const left = obj.left;
      const top = obj.top;
      const tempScaleX = scaleX * wfactor;
      const tempScaleY = scaleY * hfactor;
      const tempLeft = left * wfactor;
      const tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      console.log("_resize -> obj.setCoords();", obj.setCoords);
      if (obj.setCoords) {
        obj.setCoords();
      }
    }
    this.setState({ parentWidth: offsetWidth });
    canvas.renderAll();
    canvas.calcOffset();
  };

  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  _backgroundColor = (color) => {
    if (!color) {
      return true;
    }

    let canvas = this._fc;
    canvas.setBackgroundColor(color, () => canvas.renderAll());

    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  zoom = (type, value) => {
    const { canvasScale, SCALE_FACTOR } = this.state;
    const canvas = this._fc;
    var canvasCenter = new fabric.Point(
      canvas.getWidth() / 2,
      canvas.getHeight() / 2
    );

    switch (type) {
      case "zoomIn":
        canvas.zoomToPoint(canvasCenter, canvas.getZoom() * SCALE_FACTOR);
        break;
      case "zoomOut":
        canvas.zoomToPoint(canvasCenter, canvas.getZoom() / SCALE_FACTOR);
        break;
      case "zoomReset":
        canvas.zoomToPoint(canvasCenter, 1);
        break;
      default:
        break;
    }
    canvas.renderAll();
  };

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    let history = this._history;
    let [obj, prevState, currState] = history.getCurrent();
    history.undo();
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj);
        obj.__version -= 1;
        obj.__removed = false;
      });
    } else if (obj.__version <= 1) {
      this._fc.remove(obj);
    } else {
      obj.__version -= 1;
      obj.setOptions(JSON.parse(prevState));
      console.log("undo -> obj.setCoords();", obj.setCoords);
      obj.setCoords();
      this._fc.renderAll();
    }
    if (this.props.onChange) {
      this.props.onChange();
    }

    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    let history = this._history;
    if (history.canRedo()) {
      let canvas = this._fc;
      //noinspection Eslint
      let [obj, prevState, currState] = history.redo();
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj);
          obj.__version = 1;
        });
      } else {
        obj.__version += 1;
        obj.setOptions(JSON.parse(currState));
      }
      console.log("redo -> obj.setCoords()", obj.setCoords);
      obj.setCoords();
      canvas.renderAll();
      if (this.props.onChange) {
        this.props.onChange();
      }

      if (this.state.sendJSON) {
        this.setState({ sendJSON: false }, () => {
          this.props.canvasToImage();
          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
        });
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  videoHandler = (videoEle, options = {}) => {
    const canvas = this._fc;

    const maxWidth = this.props.width; // Max width for the image
    const maxHeight = this.props.height; // Max height for the image
    let imgWidth = videoEle.videoWidth; // Current image width
    let imgHeight = videoEle.videoHeight; // Current image height

    let widthAspectRatio = maxWidth / imgWidth;
    let heightAspectRatio = maxHeight / imgHeight;

    let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

    let finalHeight = imgHeight;
    if (imgHeight > maxHeight) {
      finalHeight = imgHeight * finalAspectRatio;
    }

    let finalWidth = imgWidth;
    if (imgWidth > maxWidth) {
      finalWidth = imgWidth * finalAspectRatio;
    }

    let nsgTop = 0;
    if (maxHeight > finalHeight) {
      nsgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / 2;
    }

    let nsgLeft = 0;
    if (maxWidth > finalWidth) {
      nsgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / 2;
    }

    videoEle.width = finalWidth;
    videoEle.height = finalHeight;
    var nsgVideo = new fabric.Image(videoEle);

    nsgVideo.set({ left: nsgLeft, top: nsgTop });
    nsgVideo.scaleToHeight(finalHeight);
    nsgVideo.scaleToWidth(finalWidth);

    canvas.setBackgroundImage(nsgVideo, () => canvas.renderAll());

    fabric.util.requestAnimFrame(function render() {
      canvas.renderAll();
      fabric.util.requestAnimFrame(render);
    });

    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  mangeTimerFromJSON = () => {
    this.timerFromJSON = setTimeout(() => {
      if (!this.state.sendJSON) {
        this.setState({ sendJSON: true });
      }
    }, 300);
  };

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */

  fromJSON(json, callback, email) {
    if (!json || !(json.backgroundImage || json.objects.length)) {
      return;
    }

    const canvas = this._fc;
    const streamImage = json;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        // canvas.renderAll();
        canvas.setWidth(this.props.width);
        canvas.setHeight(this.props.height);

        const domNode = ReactDOM.findDOMNode(this);
        const { offsetWidth, clientHeight } = domNode;
        const prevWidth = streamImage.width;
        const prevHeight = streamImage.height;

        const maxWidth = this.props.width; // Max width for the image
        const maxHeight = this.props.height; // Max height for the image

        const wfactor = (maxWidth / prevWidth).toFixed(2);
        const hfactor = (maxHeight / prevHeight).toFixed(2);
        if (canvas.backgroundImage) {
          // Need to scale background images as well
          // const bi = canvas.backgroundImage;
          canvas.backgroundImage.set({
            scaleX: canvas.backgroundImage.scaleX * wfactor,
            scaleY: canvas.backgroundImage.scaleY * hfactor,
            left: canvas.backgroundImage.left * wfactor,
            top: canvas.backgroundImage.top * hfactor,
          });
        }
        const objects = canvas.getObjects();
        for (const i in objects) {
          const obj = objects[i];
          const scaleX = obj.scaleX;
          const scaleY = obj.scaleY;
          const left = obj.left;
          const top = obj.top;
          const tempScaleX = scaleX * wfactor;
          const tempScaleY = scaleY * hfactor;
          const tempLeft = left * wfactor;
          const tempTop = top * hfactor;
          obj.scaleX = tempScaleX;
          obj.scaleY = tempScaleY;
          obj.left = tempLeft;
          obj.top = tempTop;
          console.log("fromJSON -> obj.setCoords()", obj.setCoords);
          obj.setCoords();
        }
        this.setState({ parentWidth: offsetWidth });
        canvas.calcOffset();
        canvas.renderAll();

        if (this.state.sendJSON) {
          this.setState({ sendJSON: false }, () => {
            this.props.canvasToImage(email);
            clearInterval(this.timerFromJSON);
            this.mangeTimerFromJSON();
          });
        }

        if (this.props.onChange) {
          this.props.onChange();
        }
        if (callback) callback();
      });
    }, 100);
    // }
  }

  loadObjectStream = (streamObject) => {
    let canvas = this._fc;
    let canvasObject = this._fc._objects;
    let existFlag = false;

    const leftRatio = this.props.width / streamObject.screenWidth;
    const topRatio = this.props.height / streamObject.screenHeight;
    const prevWidth = streamObject.screenWidth;
    const prevHeight = streamObject.screenHeight;

    const maxWidth = this.props.width; // Max width for the image
    const maxHeight = maxWidth * (prevHeight / prevWidth); // Max height for the image

    const wfactor = (this.props.width / prevWidth).toFixed(2);
    const hfactor = (maxHeight / prevHeight).toFixed(2);

    var vCanvas = new fabric.Canvas();
    streamObject.object.id = streamObject.id;

    vCanvas.loadFromDatalessJSON({ objects: [streamObject.object] });
    vCanvas.forEachObject(function (obj) {
      const scaleX = obj.scaleX;
      const scaleY = obj.scaleY;
      const left = obj.left;
      const top = obj.top;
      const tempScaleX = scaleX * wfactor;
      const tempScaleY = scaleY * hfactor;
      const tempLeft = left * leftRatio;
      const tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      console.log("loadObjectStream -> obj.setCoords()", obj.setCoords);
      obj.setCoords();
      canvas.add(obj);
    });
    canvas.renderAll();
  };

  /**
   * bringToFront Active Object on Canvas
   */
  bringToFront = () => {
    const canvas = this._fc;
    let objects = canvas.getObjects(); //return Array<objects>
    for (let i = 0; i < objects.length; i++) {
      canvas.bringToFront(objects[i]);
    }
    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        canvas.renderAll();

        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * sendToBack Active Object on Canvas
   */
  sendToBack = () => {
    const canvas = this._fc;
    let objects = canvas.getObjects(); //return Array<objects>
    for (let i = 0; i < objects.length; i++) {
      canvas.sendToBack(objects[i]);
    }

    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        canvas.renderAll();

        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    let discarded = this.toJSON(propertiesToInclude);
    this._fc.clear();
    this._history.clear();

    setTimeout(() => {
      this.props.canvasObjectModified();
    }, 1000);

    return discarded;
  };

  clearObjects = () => {
    const canvas = this._fc;
    let objects = canvas.getObjects(); //return Array<objects>
    for (let i = 0; i < objects.length; i++) {
      canvas.remove(objects[i]);
    }

    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        canvas.renderAll();
        this._history.clear();
        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();
    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        obj.__removed = true;
        let objState = obj.toJSON();
        obj.__originalState = objState;
        let state = JSON.stringify(objState);
        this._history.keep([obj, state, state]);
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  copy = () => {
    let canvas = this._fc;
    canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    this._clipboard.clone((clonedObj) => {
      let canvas = this._fc;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === "activeSelection") {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => canvas.add(obj));
        console.log("paste -> clonedObj.setCoords()", clonedObj.setCoords);
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      this._clipboard.top += 10;
      this._clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  };

  fromURL = (dataUrl, options = {}) => {
    const canvas = this._fc;
    fabric.Image.fromURL(dataUrl, function (oImg) {
      oImg.set({
        left: canvas.width / 2,
        top: canvas.height / 2,
        angle: 0,
        padding: 10,
        cornersize: 10,
        hasRotatingPoint: true,
      });

      oImg.scaleToHeight(canvas.height / 2);
      oImg.scaleToWidth(canvas.width / 2);

      canvas.add(oImg);
      canvas.renderAll();
    });
  };

  addImage = (dataUrl) => {
    let options = {};
    const canvas = this._fc;
    const img = new Image();
    img.onload = () => {
      const maxWidth = this.props.width; // Max width for the image
      const maxHeight = this.props.height; // Max height for the image
      let imgWidth = img.width; // Current image width
      let imgHeight = img.height; // Current image height

      let widthAspectRatio = maxWidth / imgWidth;
      let heightAspectRatio = maxHeight / imgHeight;

      let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

      let finalHeight = imgHeight * finalAspectRatio;
      let finalWidth = imgWidth * finalAspectRatio;

      let imgTop = 0;
      if (maxHeight > finalHeight) {
        imgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / 2;
      }

      let imgLeft = 0;
      if (maxWidth > finalWidth) {
        imgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / 2;
      }

      Object.assign(options, { width: finalWidth / 2 });
      Object.assign(options, { height: finalHeight / 2 });

      // set left margin
      Object.assign(options, {
        left: maxWidth / 2,
      });

      let nsgImage = new fabric.Image(img); //.scale(finalAspectRatio);
      nsgImage.set({ left: imgLeft, top: imgTop });
      nsgImage.scaleToHeight(finalHeight / 2);
      nsgImage.scaleToWidth(finalWidth / 2);

      canvas.add(nsgImage);
      if (this.state.sendJSON) {
        this.setState({ sendJSON: false }, () => {
          this.props.canvasToImage();
          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
        });
      }
    };
    if (dataUrl && isDataURL(dataUrl)) {
      img.src = dataUrl;
    } else {
      img.src = `${dataUrl}?${Date.now()}`;
    }
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    if (!dataUrl) {
      return true;
    }

    let canvas = this._fc;
    // if (options.stretched) {
    delete options.stretched;
    // Object.assign(options, {
    // width: canvas.width,
    // height: canvas.height
    // })
    // }
    // if (options.stretchedX) {
    delete options.stretchedX;
    // Object.assign(options, {
    // width: canvas.width
    // })
    // }
    // if (options.stretchedY) {
    delete options.stretchedY;
    // Object.assign(options, {
    // height: canvas.height
    // })
    // }
    let img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const maxWidth = this.props.width; // Max width for the image
      const maxHeight = this.props.height; // Max height for the image
      let imgWidth = img.width; // Current image width
      let imgHeight = img.height; // Current image height

      let widthAspectRatio = maxWidth / imgWidth;
      let heightAspectRatio = maxHeight / imgHeight;

      let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

      let finalHeight = imgHeight * finalAspectRatio;
      let finalWidth = imgWidth * finalAspectRatio;

      let imgTop = 0;
      if (maxHeight > finalHeight) {
        imgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / 2;
      }

      let imgLeft = 0;
      if (maxWidth > finalWidth) {
        imgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / 2;
      }

      let nsgImage = new fabric.Image(img); //.scale(finalAspectRatio);
      nsgImage.set({ left: imgLeft, top: imgTop });
      nsgImage.scaleToHeight(finalHeight);
      nsgImage.scaleToWidth(finalWidth);

      canvas.setBackgroundImage(nsgImage, () => canvas.renderAll());
      if (this.state.sendJSON) {
        this.setState({ sendJSON: false }, () => {
          this.props.canvasToImage();
          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
        });
      }
    };

    if (dataUrl && isDataURL(dataUrl)) {
      img.src = dataUrl;
    } else {
      img.src = `${dataUrl}?${Date.now()}`;
    }
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, options);
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top,
    });

    canvas.add(iText);
  };

  manageSelectToolTimer = () => {
    const { updateSelectedTool } = this.props;

    this.myTimer = setInterval(() => {
      updateSelectedTool(Tool.Select);
    }, 120000);
  };

  onTouchMouseDown = (e) => {
    this.moved = false;
    this.started = true;
    this.lowerCanvasEl.addEventListener(
      "mousemove",
      this.onTouchMouseMove,
      false
    );
    this.lowerCanvasEl.addEventListener("mouseup", this.onTouchMouseUp, false);
  };
  onTouchMouseMove = (e) => {
    if (this.started && !this.optionsOpen) {
      let points = this._fc.getPointer(e, false);
      this.mouselinesPath.push(this.drawLine(points.x, points.y));
    }
    this.moved = true;
  };

  onTouchMouseUp = (e) => {
    this.endDraw();
    this.moved = false;
    this.started = false;
    this.lowerCanvasEl.removeEventListener(
      "mousemove",
      this.onTouchMouseMove,
      false
    );
    this.lowerCanvasEl.removeEventListener(
      "mouseup",
      this.onTouchMouseUp,
      false
    );
  };
  endDraw = (x, y) => {
    this.xPos = null;
    this.yPos = null;

    let points = this.mouselinesPath;
    let canvas = this._fc;

    let pathPoints = [];
    for (var i = 1; i < points.length - 1; i++) {
      pathPoints.push(
        new fabric.Line(
          [points[i].x, points[i].y, points[i + 1].x, points[i + 1].y],
          {
            strokeWidth: canvas.freeDrawingBrush.width * 1,
            stroke: canvas.freeDrawingBrush.color,
            fill: canvas.freeDrawingBrush.color,
          }
        )
      );
    }
    var group = new fabric.Group(pathPoints, {
      left: points[0].x,
      top: points[0].y,
    });
    canvas.add(group);

    this.mouselinesPath = [];
  };

  onTouchStart = (e) => {
    e.preventDefault();

    forEach(
      e.touches,
      (touch) => {
        let points = this._fc.getPointer(touch, false);
        this.lines[touch.identifier] = {
          x: points.x,
          y: points.y,
        };
        this.linesPath[touch.identifier] = [];

        let tPos = { x: points.x, y: points.y };
        this.linesPathTouch[touch.identifier] = [];

        this.linesPathTouch[touch.identifier].push(tPos);

        this.linesTouch[touch.identifier] = points;
      },
      this
    );
    this.moved = false;
    this.started = true;
  };
  onTouchMove = (e) => {
    e.preventDefault();
    clearInterval(this.myTimer);
    this.manageSelectToolTimer();

    if (this.started && !this.optionsOpen) {
      forEach(
        e.touches,
        (touch) => {
          let points = this._fc.getPointer(touch, false);
          var id = touch.identifier,
            moveX = points.x - this.lines[id].x,
            moveY = points.y - this.lines[id].y,
            newPos = this.drawMulti(id, moveX, moveY);

          this.linesPath[id].push(newPos);
          this.lines[id].x = newPos.x;
          this.lines[id].y = newPos.y;

          let tPos = {
            x: this.linesTouch[id].x + (points.x - this.linesTouch[id].x),
            y: this.linesTouch[id].y + (points.y - this.linesTouch[id].y),
          };
          this.linesPathTouch[id].push(tPos);
          this.linesTouch[id].x = tPos.x;
          this.linesTouch[id].y = tPos.y;
        },
        this
      );
    }
    this.moved = true;
  };
  onTouchEnd = (e) => {
    if (e.touches.length === 0) {
      this.addObjectAfterTouch();

      if (this.props.onChange) {
        const onChange = this.props.onChange;
        setTimeout(() => {
          onChange();
        }, 10);
      }
    }
  };
  onTouchCancel = (e) => {
    if (e.touches.length === 0) {
      this.addObjectAfterTouch();

      if (this.props.onChange) {
        const onChange = this.props.onChange;
        setTimeout(() => {
          onChange();
        }, 10);
      }
    }
  };

  addObjectAfterTouch = () => {
    let canvas = this._fc;
    Object.keys(this.linesPathTouch).forEach((identifier) => {
      let points = this.linesPathTouch[identifier];

      var path = [],
        i,
        width = (canvas.freeDrawingBrush.width * 1) / 1000,
        p1 = new fabric.Point(points[0].x, points[0].y),
        p2 = new fabric.Point(
          points[1] ? points[1].x : points[0].x,
          points[1] ? points[1].y : points[0].y
        ),
        len = points.length,
        multSignX = 1,
        multSignY = 1,
        manyPoints = len > 2;

      if (manyPoints) {
        multSignX = points[2].x < p2.x ? -1 : points[2].x === p2.x ? 0 : 1;
        multSignY = points[2].y < p2.y ? -1 : points[2].y === p2.y ? 0 : 1;
      }

      path.push(
        "M ",
        p1.x - multSignX * width,
        " ",
        p1.y - multSignY * width,
        " "
      );

      for (i = 1; i < len; i++) {
        if (!p1.eq) {
          p1 = new fabric.Point(p1.x, p1.y);
        }
        if (!p2.eq) {
          p2 = new fabric.Point(p2.x, p2.y);
        }

        if (!p1.eq(p2)) {
          var midPoint = p1.midPointFrom(p2);
          // p1 is our bezier control point
          // midpoint is our endpoint
          // start point is p(i-1) value.
          path.push(
            "Q ",
            p1.x,
            " ",
            p1.y,
            " ",
            midPoint.x,
            " ",
            midPoint.y,
            " "
          );
        }
        p1 = points[i];
        if (i + 1 < points.length) {
          p2 = points[i + 1];
        }
      }

      if (!p1.eq) {
        p1 = new fabric.Point(p1.x, p1.y);
      }
      if (!p2.eq) {
        p2 = new fabric.Point(p2.x, p2.y);
      }

      if (manyPoints) {
        multSignX =
          p1.x > points[i - 2].x ? 1 : p1.x === points[i - 2].x ? 0 : -1;
        multSignY =
          p1.y > points[i - 2].y ? 1 : p1.y === points[i - 2].y ? 0 : -1;
      }
      path.push("L ", p1.x + multSignX * width, " ", p1.y + multSignY * width);

      var mpath = new fabric.Path(path.join(""), {
        originX: 0,
        originY: 0,
        strokeWidth: canvas.freeDrawingBrush.width * 1,
        stroke: canvas.freeDrawingBrush.color,
        fill: "transparent",
      });

      // var position = new fabric.Point(mpath.left + mpath.width / 2, mpath.top + mpath.height / 2);
      // position = mpath.translateToGivenOrigin(position, 'center', 'center', mpath.originX, mpath.originY);
      // mpath.top = position.y;
      // mpath.left = position.x;

      canvas.add(mpath);
      canvas.renderAll();
      mpath.setCoords();
    });

    this.lines = [];
    this.linesPath = {};

    this.linesTouch = [];
    this.linesPathTouch = {};

    this.moved = false;
    this.started = false;
    if (this.state.sendJSON) {
      this.setState({ sendJSON: false }, () => {
        this.props.canvasToImage();
        clearInterval(this.timerFromJSON);
        this.mangeTimerFromJSON();
      });
    }

    if (this.props.onChange) {
      const onChange = this.props.onChange;
      setTimeout(() => {
        onChange();
      }, 10);
    }
  };

  drawMulti = (id, moveX, moveY) => {
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.lineWidth = this._fc.freeDrawingBrush.width * this._fc.getZoom();

    this.ctx.strokeStyle = this._fc.freeDrawingBrush.color;
    this.ctx.globalCompositeOperation = "source-over";
    this.ctx.beginPath();
    this.ctx.moveTo(this.lines[id].x, this.lines[id].y);
    this.ctx.lineTo(this.lines[id].x + moveX, this.lines[id].y + moveY);
    this.ctx.stroke();
    this.ctx.closePath();

    return { x: this.lines[id].x + moveX, y: this.lines[id].y + moveY };
  };

  drawLine = (x, y) => {
    if (!this.xPos || !this.yPos) {
      this.xPos = x - this._fc._offset.left;
      this.yPos = y - this._fc._offset.top;
    }

    x = x - this._fc._offset.left;
    y = y - this._fc._offset.top;

    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.lineWidth = this._fc.freeDrawingBrush.width;
    this.ctx.strokeStyle = this._fc.freeDrawingBrush.color;
    this.ctx.globalCompositeOperation = "source-over";
    this.ctx.beginPath();
    this.ctx.moveTo(this.xPos, this.yPos);
    this.ctx.lineTo(x, y);
    this.ctx.stroke();
    this.ctx.closePath();

    let lineData = { x: this.xPos + x, y: this.yPos + y };

    this.xPos = x;
    this.yPos = y;
    return lineData;
  };

  _onObjectSelection = (e) => {
    let obj = e.target;
    if (!obj) {
      return true;
    }
    let canvas = this._fc;
    var absCoords = canvas.getAbsoluteCoords(obj);
    let nameTooltip = document.getElementById("name_tooltip");
    if (!nameTooltip) {
      nameTooltip = document.createElement("span");

      nameTooltip.setAttribute("id", `name_tooltip`);
      document.body.append(nameTooltip);
    }
    nameTooltip.innerHTML = obj.createdBy;
    nameTooltip.setAttribute(
      "style",
      `position: absolute; color: #02a2de; left: ${absCoords.left}px; top: ${absCoords.top}px`
    );
  };

  _onObjectUnSelection = (e) => {
    let nameTooltip = document.getElementById("name_tooltip");
    if (!nameTooltip) {
      nameTooltip = document.createElement("span");

      nameTooltip.setAttribute("id", `name_tooltip`);
      document.body.append(nameTooltip);
    }
    nameTooltip.innerHTML = "";
    nameTooltip.setAttribute(
      "style",
      `position: absolute; color: #02a2de; left: ${0}px; top: ${0}px`
    );
  };

  componentDidMount = () => {
    let { tool, value, undoSteps, defaultValue, backgroundColor } = this.props;

    let canvas = (this._fc = new fabric.Canvas(this._canvas, {
      preserveObjectStacking: true,
      //  renderOnAddRemove: false,
      //  skipTargetFind: true
      skipOffscreen: false,
    }));

    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: "#ff00ff",
      cornerColor: "#ff0000",
    });

    fabric.Canvas.prototype.getAbsoluteCoords = function (object) {
      return {
        left: object.left + this._offset.left,
        top: object.top + this._offset.top,
      };
    };

    this._initTools(canvas);

    // set initial backgroundColor
    this._backgroundColor(backgroundColor);

    let selectedTool = this._tools[tool];
    selectedTool.configureCanvas(this.props);
    this._selectedTool = selectedTool;

    // Control resize
    window.addEventListener("resize", this._resize, false);

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);

    // Events binding
    canvas.on("object:added", this._onObjectAdded);
    canvas.on("object:modified", this._onObjectModified);
    canvas.on("object:removed", this._onObjectRemoved);
    canvas.on("mouse:down", this._onMouseDown);
    canvas.on("mouse:move", this._onMouseMove);
    canvas.on("mouse:up", this._onMouseUp);
    canvas.on("mouse:out", this._onMouseOut);
    canvas.on("object:moving", this._onObjectMoving);
    canvas.on("object:scaling", this._onObjectScaling);
    canvas.on("object:rotating", this._onObjectRotating);
    canvas.on("selection:created", this._onObjectSelection);
    canvas.on("selection:updated", this._onObjectSelection);
    canvas.on("selection:cleared", this._onObjectUnSelection);
    // IText Events fired on Adding Text
    // canvas.on("text:event:changed", console.log)
    // canvas.on("text:selection:changed", console.log)
    // canvas.on("text:editing:entered", console.log)
    // canvas.on("text:editing:exited", console.log)

    this.disableTouchScroll();

    this._resize();

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue);
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this._resize);

  componentDidUpdate = (prevProps, prevState) => {
    const canvas = this._fc;
    if (isEqual(this.props, prevState)) {
      return;
    }

    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Select];

      if (fabric.isTouchSupported) {
        this.lowerCanvasEl = canvas.lowerCanvasEl;
        this.upperCanvasEl = canvas.upperCanvasEl;
        if (this._selectedTool == this._tools[Tool.Pencil]) {
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          // this.zoom('zoomIn')
          this.upperCanvasEl.style.zIndex = -1;
          // debugger

          this.ctx = this.lowerCanvasEl.getContext("2d");
          this.lowerCanvasEl.addEventListener(
            "touchstart",
            this.onTouchStart,
            false
          );
          this.lowerCanvasEl.addEventListener(
            "touchmove",
            this.onTouchMove,
            false
          );
          this.lowerCanvasEl.addEventListener(
            "touchend",
            this.onTouchEnd,
            false
          );
          this.lowerCanvasEl.addEventListener(
            "touchcancel",
            this.onTouchCancel,
            false
          );
          // this.lowerCanvasEl.addEventListener('mousedown', this.onTouchMouseDown, false);

          this.r = 0;
          this.g = 0;
          this.b = 0;
          this.a = 0.5;
          this.started = false;
          this.moved = false;
          this.size = 1;
          this.xPos;
          this.yPos;
          this.lines = [];
          this.linesTouch = [];
          this.optionsOpen = false;
          this.pixelRatio = 1;
          this.hasLocalStorage =
            "localStorage" in window && window["localStorage"] !== null;
          this.linesPath = {};
          this.linesPathTouch = {};
          this.mouselinesPath = [];

          // let v = canvas.viewportTransform;
          // this.ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
          // this.ctx.save();
        } else {
          this.upperCanvasEl.style.zIndex = null;
          this.lowerCanvasEl.removeEventListener(
            "touchstart",
            this.onTouchStart,
            false
          );
          this.lowerCanvasEl.removeEventListener(
            "touchmove",
            this.onTouchMove,
            false
          );
          this.lowerCanvasEl.removeEventListener(
            "touchend",
            this.onTouchEnd,
            false
          );
          this.lowerCanvasEl.removeEventListener(
            "touchcancel",
            this.onTouchCancel,
            false
          );
          this.lowerCanvasEl.removeEventListener(
            "mousedown",
            this.onTouchMouseDown,
            false
          );
        }
      }
    }

    if (
      (this.state.parentWidth !== prevProps.parentWidth &&
        this.props.width !== prevProps.width) ||
      this.props.height !== prevProps.height
    ) {
      this._resize();
    }

    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Pencil];
    }

    //Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = "default";
    this._selectedTool.configureCanvas(this.props);

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }

    if (this.props.fontSize !== prevProps.fontSize) {
      for (let i = 0; i < objects.length; i++) {
        let nsgObject = objects[i];

        nsgObject.set("fontSize", this.props.fontSize);
        nsgObject.dirty = true;
      }
    }

    if (this.props.fontName !== prevProps.fontName) {
      for (let i = 0; i < objects.length; i++) {
        let nsgObject = objects[i];

        nsgObject.set("fontFamily", this.props.fontName);
        nsgObject.dirty = true;
      }
    }

    if (this.props.lineColor !== prevProps.lineColor) {
      let objects = canvas.getActiveObjects();
      for (let i = 0; i < objects.length; i++) {
        let o = objects[i];
        if (o.stroke) {
          o.set("stroke", this.props.lineColor);
        }

        if (o.fill) {
          o.set("stroke", this.props.lineColor);
        }
      }
      canvas.renderAll();
    }

    if (this.props.lineWidth !== prevProps.lineWidth) {
      let objects = canvas.getActiveObjects();
      for (let i = 0; i < objects.length; i++) {
        let nsgObject = objects[i];
        if (nsgObject.type == "image") {
          return true;
        }
        if (nsgObject.type == "video") {
          return true;
        }

        nsgObject.set("strokeWidth", this.props.lineWidth);
        nsgObject.dirty = true;
      }
    }
    canvas.renderAll();
  };

  render = () => {
    let { className, style, width, height } = this.props;

    let canvasDivStyle = Object.assign(
      {},
      style ? style : {},
      width ? { width: width } : {},
      height ? { height: height } : { height: 512 }
    );

    return (
      <div
        className={className}
        ref={(c) => (this._container = c)}
        style={canvasDivStyle}
      >
        <canvas id={uuid4()} ref={(c) => (this._canvas = c)}>
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    );
  };
}

export default SketchField;
