import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import s from "./TextFieldGroup.module.css";

class TextFieldGroup extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,

    type: PropTypes.string,
    error: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    type: "text",
    autoFocus: false,
    isDisabled: false,
    error: "",
    onBlur: () => true,
    onFocus: () => true,
  };

  render() {
    const {
      field,
      value,
      label,
      error,
      type,
      onChange,
      onBlur,
      onFocus,
      autoFocus,
      isDisabled,
    } = this.props;
    return (
      <div
        className={cx(s["form-group"], s["-animated"], {
          [s["-active"]]: value,
        })}
      >
        <input
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          type={type}
          name={field}
          autoFocus={autoFocus}
          id={field}
          className={cx(s["form-control"], { [s["error"]]: error })}
          disabled={isDisabled}
        />
        <label htmlFor={field}>{label}</label>
        {error && <span className={s["error-field"]}>{error}</span>}
      </div>
    );
  }
}

export default TextFieldGroup;
