import axios from "axios";
import { HOST } from "../config";

export function saveGalleryLocalUpload(data) {
  return (dispatch) => axios.post("/api/download/galleryLocalUpload", data);
}

export function canvasImageToBase64(data) {
  return (dispatch) =>
    axios.post(`${HOST}/api/download/canvasImageToBase64`, data);
}

export function uploadBlobFile({ file, name }) {
  let data = new FormData();
  data.append("file", file, name);
  data.append("originalname", name);
  data.append("name", name);
  data.append("filename", name);

  return (dispatch) => axios.post(`${HOST}/api/download/uploadBlobFile`, data);
}

export function saveSlide(file, path) {
  let data = new FormData();
  data.append("file", file);
  data.append("name", path);
  data.append("path", path);

  return (dispatch) => axios.post("/api/download/saveSlide", data);
}

export function tempUpload(data) {
  return (dispatch) => axios.post(`${HOST}/api/gallery/tempUpload`, data);
}
