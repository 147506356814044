import { combineReducers } from "redux";

import flashMessages from "./reducers/flashMessages";
import auth from "./reducers/auth";
import profile from "./reducers/profile";
import workbook from "./reducers/workbook";

export default combineReducers({
  profile,
  flashMessages,
  auth,
  workbook,
});
