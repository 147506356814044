import "assets/vendors/style";

import React, { Component } from "react";
import { render } from "react-dom";
import { Router, browserHistory } from "react-router";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";

import routes from "./routes";

import LanguageProvider from "./LanguageProvider";
import { translationMessages } from "./i18n";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <Router history={browserHistory} routes={routes} />
    </LanguageProvider>
  </Provider>,
  document.getElementById("root")
);
