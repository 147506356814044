import React, { Component } from "react";
import cx from "classnames";
import map from "lodash/map";
import s from "./LecturePage.module.css";
import GoldenLayout from "golden-layout";
import Calculator from "./tools/calculator";
import Spotlight from "./tools/spotlight";
import ExcelSheet from "./tools/excelsheet";
import FunctionMachine from "./tools/functionmachine";
import TallyMarks from "./tools/tallymarks";
import DiceRoller from "./tools/diceroller";
import Abacus from "./tools/abacus";
import StopWatch from "./tools/stopwatch";
import PeriodicTable from "./tools/periodicTable";
import Protractor from "./tools/protractor";
import Dictionary from "./tools/dictionary";
import Piano from "./tools/piano";
import Draggable from "react-draggable";
// import Typing from './tools/typing'
import Memory from "./tools/games/memory/Memory";
import TicTacToe from "./tools/games/ticTacToe";
import ClickTheDots from "./tools/games/clickTheDots";
import Multiplication from "./tools/games/multiplication";
import { HOST } from "../../config";

import {
  goldenLayoutSetup,
  myLayout,
} from "./goldenLayout/golden_layout_setup";
import { buildMenu } from "./goldenLayout/menu_builder";

let toolList = {
  Maths: [
    { name: "Calculator", icon: "images/tools-icons/icon-23.png" },
    // { name: 'Interactive Angle Tool', icon: 'images/tools-icons/icon-24.png' },
    { name: "Protractor", icon: "images/tools-icons/icon-33.png" },
    // { name: 'Shapes', icon: 'images/tools-icons/icon-25.png' },
    // { name: 'Angles', icon: 'images/tools-icons/icon-26.png' },
    // { name: 'Compass', icon: 'images/tools-icons/icon-27.png' },
    // { name: 'Abacus', icon: 'images/tools-icons/icon-28.png' },
    // { name: 'Graph', icon: 'images/tools-icons/icon-32.png' },
    { name: "Excel Sheet", icon: "images/tools-icons/icon-30.png" },
    { name: "Dice", icon: "images/tools-icons/icon-31.png" },
    { name: "Tally", icon: "images/tools-icons/icon-32.png" },

    // { name: 'Sum Creator', icon: 'images/tools-icons/icon-22.png' },
    { name: "Function Machine", icon: "images/tools-icons/icon-22.png" },
    // { name: 'Fractions', icon: 'images/tools-icons/icon-22.png' },
    { name: "StopWatch", icon: "images/tools-icons/clock.30x30.png" },
    // { name: 'Countdown Timer', icon: 'images/tools-icons/icon-22.png' },
  ],
  General: [
    { name: "Spotlight", icon: "images/tools-icons/icon-34.png" },
    // { name: 'Interactive Angle Tool', icon: 'images/tools-icons/icon-24.png' },
    // { name: 'Protractor', icon: 'images/tools-icons/icon-33.png' },
    // { name: 'Shapes', icon: 'images/tools-icons/icon-25.png' },
    // { name: 'Angles', icon: 'images/tools-icons/icon-26.png' },
    // { name: 'Compass', icon: 'images/tools-icons/icon-27.png' },
    // { name: 'Abacus', icon: 'images/tools-icons/icon-28.png' },
    // { name: 'Graph', icon: 'images/tools-icons/icon-32.png' },
    // { name: 'Excel Sheet', icon: 'images/tools-icons/icon-30.png' },
    // { name: 'Dice', icon: 'images/tools-icons/icon-31.png' },
    // { name: 'Tally', icon: 'images/tools-icons/icon-29.png' },

    // { name: 'Sum Creator', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Function Machine', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Fractions', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Stopwatch', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Countdown Timer', icon: 'images/tools-icons/icon-22.png' },
  ],
  Science: [
    { name: "PeriodicTable", icon: "images/tools-icons/icon-35.png" },
    // { name: 'Interactive Angle Tool', icon: 'images/tools-icons/icon-24.png' },
    // { name: 'Protractor', icon: 'images/tools-icons/icon-33.png' },
    // { name: 'Shapes', icon: 'images/tools-icons/icon-25.png' },
    // { name: 'Angles', icon: 'images/tools-icons/icon-26.png' },
    // { name: 'Compass', icon: 'images/tools-icons/icon-27.png' },
    // { name: 'Abacus', icon: 'images/tools-icons/icon-28.png' },
    // { name: 'Graph', icon: 'images/tools-icons/icon-32.png' },
    // { name: 'Excel Sheet', icon: 'images/tools-icons/icon-30.png' },
    // { name: 'Dice', icon: 'images/tools-icons/icon-31.png' },
    // { name: 'Tally', icon: 'images/tools-icons/icon-29.png' },

    // { name: 'Sum Creator', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Function Machine', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Fractions', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Stopwatch', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Countdown Timer', icon: 'images/tools-icons/icon-22.png' },
  ],
  Language: [
    { name: "Dictionary", icon: "images/tools-icons/dic.png" },
    // { name: 'Interactive Angle Tool', icon: 'images/tools-icons/icon-24.png' },
    // { name: 'Protractor', icon: 'images/tools-icons/icon-33.png' },
    // { name: 'Shapes', icon: 'images/tools-icons/icon-25.png' },
    // { name: 'Angles', icon: 'images/tools-icons/icon-26.png' },
    // { name: 'Compass', icon: 'images/tools-icons/icon-27.png' },
    // { name: 'Abacus', icon: 'images/tools-icons/icon-28.png' },
    // { name: 'Graph', icon: 'images/tools-icons/icon-32.png' },
    // { name: 'Excel Sheet', icon: 'images/tools-icons/icon-30.png' },
    // { name: 'Dice', icon: 'images/tools-icons/icon-31.png' },
    // { name: 'Tally', icon: 'images/tools-icons/icon-29.png' },

    // { name: 'Sum Creator', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Function Machine', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Fractions', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Stopwatch', icon: 'images/tools-icons/icon-22.png' },
    // { name: 'Countdown Timer', icon: 'images/tools-icons/icon-22.png' },
  ],

  Games: [
    { name: "Memory", icon: "images/tools-icons/memory.png" },
    { name: "TicTacToe", icon: "images/tools-icons/tictaktoe.png" },
    { name: "ClickTheDots", icon: "images/tools-icons/dots.jpg" },
    { name: "Multiplication", icon: "images/tools-icons/multiply.png" },
  ],
  Music: [{ name: "Piano", icon: `${HOST}/_virtual_piano/icons/icon-128.png` }],
};
function addOrRemove(array, value) {
  var index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return array;
}

class LectureTools extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      isClosed: true,
      showTools: "",
      activeTools: [],
      gamePopup: false,
      setByTeacher: false,
    };
  }

  componentDidMount = () => {
    this.props.socket.on("teacher:activateTool", (data) => {
      this.setState({ activeTools: data.activateTool });
    });

    this.props.socket.on("teacher:multizoneGamification", (data) => {
      const { myLayoutConfig, exit } = data;
      if (exit) {
        this.setState({ gamePopup: false, setByTeacher: false }, () => {
          this._toggleFullScreen();
        });
      } else {
        this.setState({ gamePopup: true, setByTeacher: true }, () => {
          if (myLayout) {
            myLayout.destroy();
          }
          if (myLayoutConfig) {
            goldenLayoutSetup(
              document.getElementById("goldenlayoutlayoutContainer"),
              myLayoutConfig
            );
          } else {
            goldenLayoutSetup(
              document.getElementById("goldenlayoutlayoutContainer")
            );
          }
          // goldenLayoutSetup(document.getElementById("goldenlayoutlayoutContainer"), myLayoutConfig);
          buildMenu(true);

          this._toggleFullScreen();
        });
      }
    });
  };

  toggleTools = () => {
    this.setState({ isClosed: !this.state.isClosed, showTools: "" });
  };
  showTools = (type) => {
    if (type == this.state.showTools) {
      type = "";
    }

    this.setState({ showTools: type });
  };

  _toggleFullScreen = () => {
    var documentElement = document.documentElement;

    // let isFullScreen = !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
    // If fullscreen mode is active...
    if (!this.state.gamePopup) {
      // ...exit fullscreen mode
      // (Note: this can only be called on document)
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      documentElement.setAttribute("data-fullscreen", !!false);
    } else {
      // ...otherwise enter fullscreen mode
      // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
      if (documentElement.requestFullscreen)
        documentElement.requestFullscreen();
      else if (documentElement.mozRequestFullScreen)
        documentElement.mozRequestFullScreen();
      else if (documentElement.webkitRequestFullScreen) {
        // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and
        // ensures that our custom controls are visible:
        // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
        // figure[data-fullscreen=true] .controls { z-index:2147483647; }
        documentElement.webkitRequestFullScreen();
      } else if (documentElement.msRequestFullscreen)
        documentElement.msRequestFullscreen();
      documentElement.setAttribute("data-fullscreen", !!true);
    }
  };

  activateTool = (type) => {
    const { socket, sessionId } = this.props;
    const { activeTools } = this.state;
    let atools = addOrRemove(activeTools, type);

    socket.emit("teacher:activateTool", {
      sessionId: sessionId,
      activateTool: atools,
    });
    this.setState({ activeTools: atools });
  };

  toggleGamePopup = () => {
    this.setState({ gamePopup: !this.state.gamePopup }, () => {
      this._toggleFullScreen();

      if (this.state.gamePopup) {
        goldenLayoutSetup(
          document.getElementById("goldenlayoutlayoutContainer")
        );
        buildMenu();
      }
    });
  };

  render() {
    const {
      isClosed,
      showTools,
      activeTools,
      gamePopup,
      setByTeacher,
    } = this.state;
    const { Maths, General, Science, Language, Music, Games } = toolList;

    const mathTools = map(Maths, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img
            style={{ maxHeight: 20, maxWidth: 20 }}
            src={value.icon}
            alt={value.name}
          />
        </a>
      </li>
    ));

    const genralTools = map(General, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img src={value.icon} alt={value.name} />
        </a>
      </li>
    ));

    const scienceTools = map(Science, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img src={value.icon} alt={value.name} />
        </a>
      </li>
    ));

    const languageTools = map(Language, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img src={value.icon} alt={value.name} />
        </a>
      </li>
    ));

    const gamesList = map(Games, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img style={{ maxWidth: 24 }} src={value.icon} alt={value.name} />
        </a>
      </li>
    ));

    const musicList = map(Music, (value, key) => (
      <li key={key} onClick={this.activateTool.bind(this, value.name)}>
        <a href="javascript:void(0);">
          <img style={{ maxWidth: 24 }} src={value.icon} alt={value.name} />
        </a>
      </li>
    ));

    return (
      <div>
        {gamePopup ? (
          <div
            className={cx(s["nsg-toolsholderpopupcontainer"], {
              [s["nsg-toolsholdertopenpopup"]]: gamePopup,
            })}
          >
            {!setByTeacher && (
              <a
                onClick={this.toggleGamePopup}
                className={s["nsg-gamestoolsclonsebtn"]}
              >
                <i className="fa fa-times" />
              </a>
            )}

            <div id="goldenlayoutwrapper">
              <div id="goldenlayoutmenuContainer" />
              <div id="goldenlayoutfill-rest">
                <div id="goldenlayoutlayoutContainer" />
              </div>
            </div>

            {/* <div className={s['nsg-comparecast']}>
                            <figure className={s["at-screenimg"]}>
                                <iframe src={"https://localhost:4000/mathboy"} style={{ width: "100%", height: "100%" }} />
                            </figure>
                        </div> */}
          </div>
        ) : null}

        {activeTools.indexOf("Calculator") > -1 ? (
          <Calculator
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Excel Sheet") > -1 ? (
          <ExcelSheet
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Spotlight") > -1 ? (
          <Spotlight
            activeTools={activeTools}
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Function Machine") > -1 ? (
          <FunctionMachine
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Tally") > -1 ? (
          <TallyMarks
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Dice") > -1 ? (
          <DiceRoller
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Abacus") > -1 ? (
          <Abacus
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("StopWatch") > -1 ? (
          <StopWatch
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("PeriodicTable") > -1 ? (
          <PeriodicTable
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Protractor") > -1 ? (
          <Protractor
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Dictionary") > -1 ? (
          <Dictionary
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Typing") > -1 ? (
          <Dictionary
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Memory") > -1 ? (
          <Memory
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("TicTacToe") > -1 ? (
          <TicTacToe
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("ClickTheDots") > -1 ? (
          <ClickTheDots
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Multiplication") > -1 ? (
          <Multiplication
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {activeTools.indexOf("Piano") > -1 ? (
          <Piano
            socket={this.props.socket}
            sessionId={this.props.sessionId}
            closeTool={this.activateTool}
          />
        ) : null}
        {/* <Piano socket={this.props.socket} sessionId={this.props.sessionId} closeTool={this.activateTool} /> */}
        {/* {activeTools.indexOf('Typing') > -1 ? <Dictionary closeTool={this.activateTool} /> : null} */}
        {/* <DiceRoller closeTool={this.activateTool} /> */}

        {/* <Memory socket={this.props.socket} sessionId={this.props.sessionId} closeTool={this.activateTool} /> */}
        {/* <SnakeAndLadders socket={this.props.socket} sessionId={this.props.sessionId} closeTool={this.activateTool} /> */}
        {/* <ClickTheDots socket={this.props.socket} sessionId={this.props.sessionId} closeTool={this.activateTool} /> */}
        {/* <MathBoy socket={this.props.socket} sessionId={this.props.sessionId} closeTool={this.activateTool} /> */}
        <div
          className={cx(s["at-btnscollapholder"], {
            [s["at-closetools"]]: isClosed,
          })}
        >
          <a
            onClick={this.toggleTools}
            className={s["at-btncolosetools"]}
            href="javascript:void(0);"
          >
            <i className="fa fa-caret-right" />
          </a>

          <div
            className={cx(s["at-btncollaps"], s["at-btnmath"], {
              [s["at-openclose"]]: showTools == "General",
            })}
          >
            <a
              onClick={this.showTools.bind(this, "General")}
              href="javascript:void(0)"
            >
              <span>General </span>
            </a>
            <ul className={s["at-subtools"]}>{genralTools}</ul>
          </div>

          <div
            className={cx(s["at-btncollaps"], s["at-btnmath"], {
              [s["at-openclose"]]: showTools == "Maths",
            })}
          >
            <a
              onClick={this.showTools.bind(this, "Maths")}
              href="javascript:void(0)"
            >
              <span>Maths</span>
            </a>
            <ul className={s["at-subtools"]}>{mathTools}</ul>
          </div>

          <div
            className={cx(s["at-btncollaps"], s["at-btnscience"], {
              [s["at-openclose"]]: showTools == "Science",
            })}
          >
            <a
              onClick={this.showTools.bind(this, "Science")}
              href="javascript:void(0)"
            >
              <span>Science</span>
            </a>
            <ul className={s["at-subtools"]}>{scienceTools}</ul>
          </div>

          <div
            className={cx(s["at-btncollaps"], s["at-btnlanguage"], {
              [s["at-openclose"]]: showTools == "Language",
            })}
          >
            <a
              onClick={this.showTools.bind(this, "Language")}
              href="javascript:void(0)"
            >
              <span>Language</span>
            </a>
            <ul className={s["at-subtools"]}>{languageTools}</ul>
          </div>

          <div
            className={cx(s["at-btncollaps"], s["at-btnlanguage"], {
              [s["at-openclose"]]: showTools == "Music",
            })}
          >
            <a
              onClick={this.showTools.bind(this, "Music")}
              href="javascript:void(0)"
            >
              <span>Music</span>
            </a>
            <ul className={s["at-subtools"]}>{musicList}</ul>
          </div>

          <div
            className={cx(s["at-btncollaps"], s["at-btnlanguage"], s["hide"])}
          >
            <a onClick={this.toggleGamePopup} href="javascript:void(0)">
              <span>Multi-Zone Gamification</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default LectureTools;
