import axios from "axios";
import { HOST } from "../config";

export function searchWord(word) {
  return (dispatch) => axios.get(`${HOST}/api/tools/lang/entries/${word}`);
}

export function thesaurusSearch(word) {
  return (dispatch) => axios.get(`/api/tools/lang/thesaurus/${word}`);
}
