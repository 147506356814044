import React, { Component } from "react";
import FlashMessage from "./FlashMessage";
import { connect } from "react-redux";
import { deleteFlashMessage } from "../../actions/flashMessages";
import PropTypes from "prop-types";
import s from "./FlashMessage.css";
import cx from "classnames";
class FlashMessagesList extends Component {
  render() {
    const messages = this.props.messages.map((message) => (
      <FlashMessage
        key={message.id}
        message={message}
        deleteFlashMessage={this.props.deleteFlashMessage}
      />
    ));
    return (
      <div className={cx(s["alert-holder"], s["at-openerrormesg"])}>
        {messages}
      </div>
    );
  }
}

FlashMessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    messages: state.flashMessages,
  };
}

export default connect(mapStateToProps, { deleteFlashMessage })(
  FlashMessagesList
);
