import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import map from "lodash/map";
import math from "mathjs";
import ToolHolder from "../holder";
import TextFieldGroup from "../../../common/TextFieldGroup";
import s from "./FunctionMachine.module.css";

const operators = {
  "+": "+",
  "-": "-",
  "*": "*",
  "/": "/",
  "^": "^",
};

class FunctionMachine extends Component {
  static propTypes = {
    closeTool: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      x: "",
      y: "",
      operator: "+",
      result: "",
    };
  }

  componentDidMount = () => {
    const { socket, sessionId } = this.props;

    socket.on("teacher:functionmachine", (data) => {
      this.setState(data.functionmachine);
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { socket, sessionId, isAccessGranted } = this.props;
    const { x, y, operator, result } = this.state;
    if (x && y) {
      this.setState(
        { result: math.eval(`x ${operator} y`, this.state) },
        () => {
          if (isAccessGranted) {
            socket.emit("student:functionmachine", {
              sessionId: sessionId,
              functionmachine: this.state,
            });
          }
        }
      );
    } else if (x && result) {
      this.setState(
        { y: math.eval(`x ${operator} result`, this.state) },
        () => {
          if (isAccessGranted) {
            socket.emit("student:functionmachine", {
              sessionId: sessionId,
              functionmachine: this.state,
            });
          }
        }
      );
    } else if (y && result) {
      this.setState(
        { x: math.eval(`result ${operator} y`, this.state) },
        () => {
          if (isAccessGranted) {
            socket.emit("student:functionmachine", {
              sessionId: sessionId,
              functionmachine: this.state,
            });
          }
        }
      );
    }
  };

  onChange = (e) => {
    const { socket, sessionId, isAccessGranted } = this.props;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (isAccessGranted) {
        socket.emit("student:functionmachine", {
          sessionId: sessionId,
          functionmachine: this.state,
        });
      }
    });
  };

  resetForm = () => {
    const { socket, sessionId, isAccessGranted } = this.props;
    this.setState({ x: "", y: "", operator: "+", result: "" }, () => {
      if (isAccessGranted) {
        socket.emit("student:functionmachine", {
          sessionId: sessionId,
          functionmachine: this.state,
        });
      }
    });
  };
  render() {
    let { closeTool } = this.props;

    const options = map(operators, (val, key) => (
      <option key={val} value={val}>
        {key}
      </option>
    ));

    return (
      <ToolHolder
        name={"Function Machine"}
        classname={"at-sumcreatorholder"}
        closeTool={closeTool}
        tool={"Function Machine"}
      >
        <div className={s["at-sumcreatercontent"]}>
          <form onSubmit={this.onSubmit}>
            {/* <ul className={s["at-sumarea"]}> */}
            {/* <li> */}
            <div className={cx("row", s["at-sumarea"])}>
              <div className="col-6">
                <TextFieldGroup
                  error={""}
                  label="Value 1"
                  onChange={this.onChange}
                  value={`${this.state.x}`}
                  field="x"
                  type="number"
                />
              </div>
              {/* </li> */}
              {/* <li> */}
              <div className={"col-6"}>
                <div className={"form-group"}>
                  <select
                    className="form-control"
                    name="operator"
                    onChange={this.onChange}
                    value={this.state.operator}
                  >
                    <option value="" disabled>
                      Choose Your Operator
                    </option>
                    {options}
                  </select>
                </div>
              </div>

              {/* </li> */}
              {/* <li> */}
              <div className="col-6">
                <TextFieldGroup
                  error={""}
                  label="Value 2"
                  onChange={this.onChange}
                  value={`${this.state.y}`}
                  field="y"
                  type="number"
                />
              </div>
              {/* </li> */}
              {/* <li> */}
              <div className="col-6">
                <TextFieldGroup
                  error={""}
                  label="Result"
                  onChange={this.onChange}
                  value={`${this.state.result}`}
                  field="result"
                  type="number"
                />
              </div>
              {/* </li> */}
              {/* </ul> */}

              <div className={s["at-btnholder"]}>
                <button
                  type="submit"
                  color="success"
                  className={cx("btn at-themebtn")}
                >
                  Calculate
                </button>
                <button
                  onClick={this.resetForm}
                  type="button"
                  className={cx("btn at-themebtn")}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </ToolHolder>
    );
  }
}

export default FunctionMachine;
