"use strict";

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;
class Pencil extends FabricCanvasTool {
  configureCanvas(props) {
    if (fabric.isTouchSupported) {
      this._canvas.isDrawingMode = this._canvas.selection = false;
    } else {
      this._canvas.isDrawingMode = true;
    }
    this._canvas.freeDrawingBrush.width = Number(props.lineWidth);
    this._canvas.freeDrawingBrush.color = props.lineColor;
  }
}

export default Pencil;
