import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import socketIOClient from "socket.io-client";
import DropZone from "react-dropzone";
import Draggable from "react-draggable";
import map from "lodash/map";
import { Spin, Space, Button, Tooltip, Popover, Slider, Layout } from "antd";
import GallerySearch from "./GallerySearch";
import { SketchPicker, CirclePicker } from "react-color";
import { OpenVidu } from "openvidu-browser";
import { Tools } from "../drawing";
import PopupTypeTwo from "../common/PopupTypeTwo";
import CastPopup from "./CastPopup";
import PopupTypeOne from "../common/PopupTypeOne";
import { Bar } from "react-chartjs-2";
import { isMobile } from "react-device-detect";
import {
  getWorkbookDetails,
  exportUserWorkbook,
  getDocDetails,
  saveAnnotation,
  getSessionId,
} from "../../actions/workbook";
import { getUserProfile } from "../../actions/users";
import { addSlideToEnd } from "../../actions/workbookActions";
import { canvasImageToBase64 } from "../../actions/galleryActions";

import { getToken } from "../../actions/openviduActions";
import { addFlashMessage } from "../../actions/flashMessages.js";
import { ValidURL } from "../../utils/helper";
import s from "./LecturePage.module.css";
import LectureTopBar from "./LectureTopBar";
import LectureCanvas from "./LectureCanvas";
import LectureTools from "./LectureTools";
import GroupChat from "./GroupChat";
import { HOST } from "../../config";
import Topbar from "./Topbar";
// import OpenViduComponent from './OpenVidu/App'
import {
  createFromIconfontCN,
  ArrowRightOutlined,
  FileAddOutlined,
  FundProjectionScreenOutlined,
  PlayCircleOutlined,
  SaveOutlined,
  UsergroupAddOutlined,
  ExpandOutlined,
  DragOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  SelectOutlined,
  PauseCircleOutlined,
  VideoCameraOutlined,
  ZoomInOutlined,
  SearchOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import Settings from "./Settings";

const CloudIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2263175_tkn0h9jacf.js", // 在 iconfont.cn 上生成
});
class LecturePage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getUserProfile: PropTypes.func.isRequired,
    getWorkbookDetails: PropTypes.func.isRequired,
    exportUserWorkbook: PropTypes.func.isRequired,
    canvasImageToBase64: PropTypes.func.isRequired,
    addSlideToEnd: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      workbook: {},
      alertBox: {
        showAlert: false,
        alertType: "endLecture",
        alertMessage: "Agreed ?",
      },

      flags: {
        mainTools: true,
        comparePopup: false,
      },

      subscribers: [],

      slides: [],
      slideHandler: {
        currentSlide: -1,
        totalSlides: 0,
        isDirty: 0,
        preview: true,
      },
      sessionId: "",
      email: "",

      multiPageDoc: {
        current: -1,
        totalSlides: 0,
        details: [],
      },

      groupChat: [],
      tool: Tools.Select,
      imgGalleryFlag: false,

      publisher: null,
      compareArray: [],
      comparePopupFlag: false,
      showSessionIdModal: false,
      lectureToolsFlag: true,
      isVideoPaused: false,
      isAudioPaused: true,

      //stream states are under
      isDirtySlide: false,
      studentLastSyncTime: 0,
      isAccessGranted: false,
      streamImage: [],
      pause: false,
      exit: false,
      showSetting: false,
      studentNavigation: true,
      showImageUplaoder: false,
      studentLiveImageSearch: true,
      studentSettings: true,
      toolbarSize: "lg",
      votingData: {},
      votingAnswer: {},
      startActivityOnly: false,
      sendJSON: true,
      isLoading: false,
      showResults: false,
      videoCall: false,
      videoDrawer: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Voting Activity Results",
            backgroundColor: "rgba(0,100,0,0.2)",
            borderColor: "rgba(0,100,0,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0,100,0,0.4)",
            hoverBorderColor: "rgba(0,100,0,1)",
            data: [],
          },
        ],
      },
      navTypeAfterSave: "",
      navSlideIndexAfterSave: -1,

      // Sketch Props
      lineWidth: 3,
      fontSize: 20,
      fontName: "Agency FB",
      lineColor: "black",
      fillColor: "transparent",
      toolsPosition: "both",
    };

    this.lastObject = "";
    this.lastUpdateTime = 0;
  }
  componentWillMount = () => {};
  componentDidMount = () => {
    if (localStorage.getItem("toolbarSize")) {
      this.setState({ toolbarSize: localStorage.getItem("toolbarSize") });
    }

    var isiPad = navigator.userAgent.match(/iPad/i) != null;
    if (isiPad) {
      this._toggleFullScreen();
    }

    const location = this.props.location;
    const { email, sessionId } = location.query;
    this.setState({ email, sessionId }, () => {
      this.handleSocketIO();
      {
        process.env.NODE_ENV !== "development" && this.createSession();
      }
    });

    this.teacherStreamResizHandler.addEventListener(
      "mousedown",
      this.initTeacherStreamResizeDrag,
      false
    );
  };

  initTeacherStreamResizeDrag = (e) => {
    this.startX = e.clientX;
    this.startY = e.clientY;
    this.startWidth = parseInt(
      document.defaultView.getComputedStyle(this.teacherStreamContainer).width,
      10
    );
    this.startHeight = parseInt(
      document.defaultView.getComputedStyle(this.teacherStreamContainer).height,
      10
    );
    document.documentElement.addEventListener("mousemove", this.doDrag, false);
    document.documentElement.addEventListener("mouseup", this.stopDrag, false);
  };
  doDrag = (e) => {
    let width = this.startWidth + e.clientX - this.startX;
    let height = this.startHeight + e.clientY - this.startY;
    // let final = (w + h) / 2;

    this.teacherStreamContainer.style.width = width + "px";
    // this.teacherStreamContainer.style.height = (height) + 'px';
    console.log(
      this.startWidth,
      e,
      this.startX,
      this.teacherStreamContainer.style.width
    );
  };

  stopDrag = (e) => {
    document.documentElement.removeEventListener(
      "mousemove",
      this.doDrag,
      false
    );
    document.documentElement.removeEventListener(
      "mouseup",
      this.stopDrag,
      false
    );
  };

  componentWillUnmount = () => {
    this.leaveSession();
  };
  restartLecture = async () => {
    const { getSessionId } = this.props;
    const { workbook } = this.state;
    let result = await getSessionId();
    const { sessionId } = result.data;
    this.setState({ sessionId });
    window.open(
      `${HOST}/lecture?id=${workbook._id}&sessionId=${sessionId}`,
      "_self"
    );
  };

  handleSocketIO = () => {
    const { sessionId, email } = this.state;

    this.socket = socketIOClient(HOST, {
      transports: ["websocket"],
      query: `sessionId=${sessionId}&role=Student&username=${email}`,
    });
    this.socket.on("connect", (_socket) => {
      console.error("connected to socket");
      this.socket.emit("joinRoom", { sessionId, email });
      this.socket.on("teacher:videoCall", (data) => {
        this.setState({ videoCall: data.videoCall });
        // source: { videoCall: this.state.videoCall },
        // sessionId: sessionId,
      });

      this.socket.on("noSessionFound", (data) => {
        if (data.email === email) {
          window.location.href = "/";
        }
      });

      this.socket.on("lectureNotStartedYet", (data) => {
        if (data.email === email) {
          window.location.href = "/";
        }
      });

      this.socket.on("object:workbook", (data) => {
        const { workbook, lectureToolsFlag, slideHandler } = data;
        if (workbook.slide) {
          this.setState(
            {
              lectureToolsFlag,
              workbook,
              slides: workbook.slide,
              slideHandler: slideHandler,
            },
            () => {
              // $(this.sliderholderarea).mCustomScrollbar({ axis: "x", contentTouchScroll: false });
            }
          );
          // this._canvas.slideHandler(slideHandler.currentSlide)
        }
      });

      this.socket.on("toggleStudentStreamAudio", (data) => {
        const { email } = this.state;
        if (email == data.email) {
          this.toggleMic();
        }
      });

      this.socket.on("changeStudentCastQuality", (data) => {
        const { email } = this.state;
        if (email == data.email) {
          this.changeCastQuality(data.quality);
        }
      });

      this.socket.on("object:workbook:refresh", (data) => {
        const { slides } = data;
        this.setState({ slides }, () => {
          // $(this.sliderholderarea).mCustomScrollbar({ axis: 'x', contentTouchScroll: false });
        });
      });

      this.socket.on("object:slide", (data) => {
        const { email } = this.state;
        if (!this.state.isDirtySlide || data.sync) {
          let d = new Date();

          let parsedData = JSON.parse(data.dataURL);
          let parsedDataKeys = Object.keys(parsedData);
          if (!parsedData.objects.length && parsedDataKeys.length === 3) {
            this._canvas._clear();

            this.setState({ slideHandler: data.slideHandler });
          } else {
            if (this.state.sendJSON) {
              this.setState({ sendJSON: false }, () => {
                if (!data.email || data.email !== email) {
                  this.setState(
                    {
                      streamImage: data.dataURL,
                      slideHandler: data.slideHandler,
                    },
                    () => {
                      // this.navigateSlide('jump', data.slideHandler.currentSlide);
                      this._canvas._loadFromJSONStream(parsedData);
                    }
                  );

                  // if (this._canvas._video) {
                  //   this._canvas._video.currentTime = parsedData.currentTime;
                  // }
                }
                clearInterval(this.timerFromJSON);
                this.mangeTimerFromJSON();
              });
            }
          }
          this.setState({
            isDirtySlide: false,
            studentLastSyncTime: +new Date(),
          });
        }
      });

      this.socket.on("syncStudent_join", (data) => {
        if (
          data.email === this.state.email &&
          +new Date() - this.state.studentLastSyncTime > 2000
        ) {
          this._canvas._clear();
          let parsedData = JSON.parse(data.dataURL);
          this.setState({ streamImage: data.dataURL });
          this._canvas._loadFromJSONStream(parsedData);
          if (this._canvas._video) {
            this._canvas._video.currentTime = parsedData.currentTime || 0;
          }

          clearInterval(this.timerFromJSON);
          this.mangeTimerFromJSON();
          this.setState({
            isDirtySlide: false,
            studentLastSyncTime: +new Date(),
          });
        }
      });

      this.socket.on("pauseStudentAccess", (data) => {
        this.setState({ pause: data.pause });
      });

      this.socket.on("exitLecture", () => {
        this.setState({ exit: true });
      });

      this.socket.on("teacher:showSlides", (data) => {
        this.setState({
          slideHandler: { ...this.state.slideHandler, preview: data.preview },
        });
      });

      this.socket.on("grantedAccess", (data) => {
        const { email } = this.state;
        let isAccessGranted = data.indexOf(email) > -1;
        this.setState({ isAccessGranted });
      });

      this.socket.on("galleryImageLink", (data) => {
        this.studentImage(data.filePath);
      });

      this.socket.on("toggleStudentNav", (data) => {
        this.setState({ studentNavigation: data.studentNavigation });
      });

      this.socket.on("toggleStudentLiveImageSearch", (data) => {
        this.setState({ studentLiveImageSearch: data.studentLiveImageSearch });
      });

      this.socket.on("toggleStudentSettings", (data) => {
        this.setState({ studentSettings: data.studentSettings });
      });

      // this.socket.emit("activityStatus", { sessionId: sessionId });
      // this.socket.on("waitingToStartActivity", () => {
      //   const { email } = this.state
      //   this.setState({
      //     votingData: { activityOnly: true, data: [{ options: [], question: "<h1>Waiting for Teacher / Instructor to Start Activity</h1>", type: "html" }], questionIndex: 0, votingDrivenType: 'Teacher Driven', votingStudentList: [email] },
      //     votingAnswer: {}
      //   })
      // });

      this.socket.on("startActivityOnly", (data) => {
        this.setState({ startActivityOnly: data.isActivity });
      });

      this.socket.on("endActivityOnly", (data) => {
        window.location.href = "/";
      });

      this.socket.emit("syncVotingActivity", { sessionId, email });

      this.socket.on("onSyncVotingActivity", (data) => {
        if (data.email != email) {
          return true;
        }

        this.setState({ votingData: data, votingAnswer: {} }, () => {
          let finalQuestions;
          if (this.state.votingData.data) {
            let finalQuiz = this.state.votingData.data[
              this.state.votingData.data.length - 1
            ];
            if (finalQuiz) {
              let quiz = finalQuiz.question;
              let isEndQuestion;
              if (
                quiz ==
                "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>"
              ) {
                isEndQuestion = true;
              }
              if (!isEndQuestion) {
                let lastQuestion = {
                  options: [],
                  question:
                    "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>",
                  type: "html",
                };
                finalQuestions = this.state.votingData.data;
                finalQuestions.push(lastQuestion);

                this.setState({
                  votingData: {
                    ...this.state.votingData,
                    data: finalQuestions,
                  },
                });
              }
            }
          }
        });

        // debugger;
        // if (data.votingStudentList && data.votingStudentList.length && (data.votingStudentList.indexOf(email) > -1)) {
        //   this.setState({ votingData: data, votingAnswer: {} }, () => {
        //     let finalQuestions;
        //     if (this.state.votingData.data) {
        //       let finalQuiz = this.state.votingData.data[this.state.votingData.data.length - 1]
        //       let quiz = finalQuiz.question;
        //       let isEndQuestion;
        //       if (quiz == "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>") {
        //         isEndQuestion = true
        //       }
        //       if (!isEndQuestion) {
        //         let lastQuestion = { options: [], question: "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>", type: "html" };
        //         finalQuestions = this.state.votingData.data;
        //         finalQuestions.push(lastQuestion);

        //         this.setState({ votingData: { ...this.state.votingData, data: finalQuestions } });
        //       }
        //     }
        //   })
        // }

        // this.setState({ votingData: { votingStudentList: votingStudentList, data: finalQuestions } });
        // if (data) {

        // }

        // this.setState({ votingData: data, votingAnswer: {} }, () => {
        //   let finalQuestions;
        //   if (this.state.votingData.data) {
        //     let lastQuestion = { options: [], question: "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>", type: "html" };
        //     finalQuestions = this.state.votingData.data;
        //     finalQuestions.push(lastQuestion);

        //     this.setState({ votingData: { ...this.state.votingData, data: finalQuestions } });
        //   }
        // })
      });

      this.socket.on("startVoting", (data) => {
        let hasAccess;
        let alreadyAccess;
        if (
          this.state.votingData &&
          this.state.votingData.votingStudentList &&
          this.state.votingData.votingStudentList.length
        ) {
          alreadyAccess =
            this.state.votingData.votingStudentList.indexOf(email) > -1;
        }

        if (
          alreadyAccess &&
          data.votingStudentList &&
          data.votingStudentList.length &&
          data.votingStudentList.indexOf(email) > -1
        ) {
          hasAccess = true;
        }

        if (
          hasAccess &&
          alreadyAccess &&
          data.data &&
          this.state.votingData &&
          this.state.votingData.data &&
          data.data.length == this.state.votingData.data.length - 1
        ) {
          return true;
        }
        //  = this.state.votingData.votingStudentList.indexOf(email) > -1
        // hasAccess = this.state.votingData;

        this.setState({ votingData: data, votingAnswer: {} }, () => {
          let finalQuestions;
          if (this.state.votingData.data) {
            let finalQuiz = this.state.votingData.data[
              this.state.votingData.data.length - 1
            ];
            if (finalQuiz) {
              let quiz = finalQuiz.question;
              let isEndQuestion;
              if (
                quiz ==
                "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>"
              ) {
                isEndQuestion = true;
              }
              if (!isEndQuestion) {
                let lastQuestion = {
                  options: [],
                  question:
                    "<h1>You have reached the end of the activity. Please wait for further instructions from your teacher</h1>",
                  type: "html",
                };
                finalQuestions = this.state.votingData.data;
                finalQuestions.push(lastQuestion);

                this.setState({
                  votingData: {
                    ...this.state.votingData,
                    data: finalQuestions,
                  },
                });
              }
            }
          }
        });
      });

      this.socket.on("votingDrivenType", (data) => {
        this.setState({ votingData: { ...this.state.votingData, ...data } });
      });

      this.socket.on("showResults", (data) => {
        this.setState({ showResults: data.showResults });
      });

      this.socket.on("endActivity", () => {
        window.location.href = "/";
      });
    });
  };

  mangeTimerFromJSON = () => {
    this.timerFromJSON = setTimeout(() => {
      if (!this.state.sendJSON) {
        this.setState({ sendJSON: true });
      }
    }, 300);
  };

  _canvasToImage = () => {
    const { email, sessionId } = this.state;
    if (this.state.isAccessGranted) {
      if (this._canvas._sketch) {
        // debugger;
        // let current =this._canvas._sketch._history
        if (
          this._canvas._sketch._history.current &&
          this._canvas._sketch._history.current[0] &&
          this._canvas._sketch._history.current[0] !== this.lastObject
        ) {
          this.lastObject = this._canvas._sketch._history.current[0];
          let last = this._canvas._sketch._history.current[0];

          let myobj = this._canvas._sketch._fc.getObjects();
          let streamObj = JSON.parse(this.state.streamImage).objects;

          if (
            !last.fromJSON &&
            myobj.length > streamObj.length &&
            this._canvas.state.tool !== "erase"
          ) {
            this.socket.emit("studentSlideDraw", {
              source: {
                object: last.toJSON(["createdBy"]),
                screenWidth: last.canvas.width,
                screenHeight: last.canvas.height,
                id: last.id,
              },
              sessionId: sessionId,
              email: email,
            });
          }
        }
      }
    } else {
      if (
        this._canvas._sketch &&
        +new Date() - this.state.studentLastSyncTime > 3000
      ) {
        if (
          this._canvas._sketch._history.current &&
          this._canvas._sketch._history.current[0] &&
          this._canvas._sketch._history.current[0] !== this.lastObject
        ) {
          this.setState({ isDirtySlide: true });
        }
      }
    }
  };

  canvasObjectModified = (sendFull) => {
    if (this.state.isAccessGranted) {
      const { email, sessionId } = this.state;
      if (this._canvas._sketch) {
        if (sendFull == "drawingModifiedByStudent") {
          let dataURL = this._canvas._sketch.toJSON(["width", "height"]);

          if (this._canvas._video) {
            dataURL.currentTime = this._canvas._video.currentTime;
          }

          dataURL = JSON.stringify(dataURL);
          this.socket.emit("drawingModifiedByStudent", {
            source: {
              dataURL,
              type: "fullJson",
            },
            sessionId: sessionId,
            email: email,
          });
        } else {
          // var dataURL = this._canvas._sketch.toJSON();
          let currentObject = this._canvas._sketch.toJSON(["width", "height"]);
          //  const { streamImage } = this.state; let obj =
          // currentObject.objects.concat(streamImage.objects); let dataURL =
          // currentObject; dataURL.objects = obj; var dataURL = this._canvas._sketch.toDataURL();
          if (
            this._canvas._sketch._history.current &&
            this._canvas._sketch._history.current[0] &&
            this._canvas._sketch._history.current[0] !== this.lastObject
          ) {
            this.lastObject = this._canvas._sketch._history.current[0];
            let last = this._canvas._sketch._history.current[0];

            let myobj = this._canvas._sketch._fc.getObjects();
            let streamObj = JSON.parse(this.state.streamImage).objects;

            if (
              !last.fromJSON &&
              myobj.length > streamObj.length &&
              this._canvas.state.tool !== "erase"
            ) {
              this.socket.emit("studentSlideDraw", {
                source: {
                  object: currentObject,
                  type: "json",
                  renderType: sendFull ? "sendFull" : "",
                },
                sessionId: sessionId,
                email: email,
              });
            }
          }
        }
      }
    } else {
      if (
        this._canvas._sketch &&
        +new Date() - this.state.studentLastSyncTime > 3000
      ) {
        if (
          this._canvas._sketch._history.current &&
          this._canvas._sketch._history.current[0] &&
          this._canvas._sketch._history.current[0] !== this.lastObject
        ) {
          this.setState({ isDirtySlide: true });
        }
      }
    }
  };

  _grantAcess = (email) => {
    const student = this.state.accessToStudent;

    const index = student.indexOf(email);
    if (index > -1) {
      student.splice(index, 1);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: student,
        sessionId: this.props.sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      requestFromStudent.splice(index, 1);
      this.setState({ requestFromStudent });
    } else {
      student.push(email);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: [...new Set(student)],
        sessionId: this.props.sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      requestFromStudent.push(email);
      this.setState({ requestFromStudent: [...new Set(requestFromStudent)] });
    }
    this.setState({
      accessToStudent: [...new Set(student)],
    });
  };

  getWorkbookDetails = () => {};

  createSession = () => {
    const { getToken, addFlashMessage, location, auth } = this.props;
    const { sessionId, email } = location.query;
    const { isAudioPaused } = this.state;

    this.OV = new OpenVidu();
    this.OV.setAdvancedConfiguration({
      screenShareChromeExtension:
        "https://chrome.google.com/webstore/detail/contribute-cast/gbmlmheknojbpmagkgfcjlalpmeiflko",
    });

    this.session = this.OV.initSession();

    this.session.on("streamCreated", (event) => {
      let subscriber = this.session.subscribe(event.stream, undefined);

      let username = JSON.parse(
        subscriber.stream.connection.data.replace("}%/%{", ",")
      ).clientData;
      if (username.indexOf(" - Teacher") > -1) {
        subscriber.addVideoElement(this.teacherStream);
        this.setState({ publisher: subscriber });
      }
    });

    this.session.on("streamDestroyed", (event) => {
      let username = JSON.parse(
        event.stream.connection.data.replace("}%/%{", ",")
      ).clientData;
      if (username.indexOf(" - Teacher") > -1) {
        this.deleteSubscriber(event.stream.streamManager);
        this.setState({ publisher: null });
      }
    });

    getToken({ sessionId, username: `${email} - Student` })
      .then((res) => {
        const { token, username } = res.data;
        this.session
          .connect(token, { clientData: `${email} - Student` })
          .then(() => {
            let localPublisher = this.OV.initPublisher(undefined, {
              audioSource: undefined, // The source of audio. If undefined default microphone
              ...(!isMobile ? { videoSource: "screen" } : {}),
              // The source of video. If undefined default webcam
              publishAudio: !isAudioPaused, // Whether you want to start publishing with your audio unmuted or not
              publishVideo: true, // Whether you want to start publishing with your video enabled or not
              resolution: "640x480", // The resolution of your video
              frameRate: 20, // The frame rate of your video
              insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
              mirror: false, // Whether to mirror your local video or not
              screen: true, // Whether to mirror your local video or not
            });

            this.session.publish(localPublisher);
            this.localPublisher = localPublisher;
            // localPublisher.addVideoElement(this.teacherStream)
            // this.session.publish(publisher);

            // this.publisher = publisher;
          })
          .catch((error) => {
            addFlashMessage({
              type: "error",
              text:
                error.message ||
                "There was an error connecting to the session.",
            });
          });
      })
      .catch((error) => {
        const { message } = error.response.data;
        addFlashMessage({
          type: "error",
          text: message || "There was an error while getting session token.",
        });
      });
  };

  toggleMic = () => {
    this.localPublisher.publishAudio(!this.localPublisher.stream.audioActive);
    this.setState({ isAudioPaused: !this.localPublisher.stream.audioActive });
  };

  leaveSession() {
    if (this.session) {
      this.session.disconnect();
    }

    this.setState({ subscribers: [] });

    delete this.publisher;
    delete this.session;
    delete this.OV;
  }

  deleteSubscriber = (streamManager) => {
    const { subscribers } = this.state;
    let index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      subscribers.splice(index, 1);
    }

    this.setState({ subscribers });
  };
  changeCastQuality = (quality) => {
    this.session.unpublish(this.localPublisher);

    let localPublisher = this.OV.initPublisher(undefined, {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: "screen", // The source of video. If undefined default webcam
      publishAudio: !this.state.isAudioPaused, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: "640x480", // The resolution of your video
      frameRate: quality, // The frame rate of your video
      insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
      mirror: false, // Whether to mirror your local video or not
      screen: true, // Whether to mirror your local video or not
    });

    this.session.publish(localPublisher);
    this.localPublisher = localPublisher;
  };

  previewSlide = (index) => {
    this.navigateSlide("jump", index);
  };

  manageTecherStream = (type) => {
    const { publisher, screenShare, isVideoPaused, isAudioPaused } = this.state;

    switch (type) {
      case "call":
        if (!publisher) {
          let localPublisher = this.OV.initPublisher(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: undefined, // The source of video. If undefined default webcam
            publishAudio: !isAudioPaused, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: !isVideoPaused, // Whether you want to start publishing with your video enabled or not
            resolution: "640x480", // The resolution of your video
            frameRate: 20, // The frame rate of your video
            insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
            mirror: false, // Whether to mirror your local video or not
          });

          this.session.publish(localPublisher);
          localPublisher.addVideoElement(this.teacherStream);
          this.setState({ publisher: localPublisher });

          //remove this line
          this.setState({
            subscribers: [
              ...this.state.subscribers,
              localPublisher,
              localPublisher,
              localPublisher,
              localPublisher,
              localPublisher,
            ],
          });
        }
        break;
      case "audio":
        publisher.publishAudio(!publisher.stream.audioActive);
        this.setState({ isAudioPaused: !publisher.stream.audioActive });
        break;
      case "screen":
        if (publisher.properties.videoSource !== "screen") {
          let localPublisher = this.OV.initPublisher(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: "screen", // The source of video. If undefined default webcam
            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: true, // Whether you want to start publishing with your video enabled or not
            resolution: "640x480", // The resolution of your video
            frameRate: 20, // The frame rate of your video
            insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
            mirror: false, // Whether to mirror your local video or not
            screen: true, // Whether to mirror your local video or not
          });
          this.session.unpublish(publisher);

          this.session.publish(localPublisher);
          localPublisher.addVideoElement(this.teacherStream);
          this.setState({ publisher: localPublisher });
          this.setState({
            subscribers: [
              ...this.state.subscribers,
              localPublisher,
              localPublisher,
              localPublisher,
              localPublisher,
              localPublisher,
            ],
          });
        } else {
          this.session.unpublish(publisher);
          this.setState({ publisher: null }, () => {
            this.manageTecherStream("call");
          });
        }
        break;
      case "video":
        publisher.publishVideo(!publisher.stream.videoActive);

        this.setState({ isVideoPaused: !publisher.stream.videoActive });
        break;
      case "end":
        this.session.unpublish(publisher);
        this.setState({ publisher: null });
        break;
      default:
        console.error(
          "switch case not handled in function manageTecherStream",
          type
        );
    }
  };

  lectureRecording = () => {
    const { addFlashMessage } = this.props;

    if (!this.localRecorder) {
      let localRecorder = this.OV.initPublisher(
        undefined,
        {
          audioSource: undefined, // The source of audio. If undefined default microphone
          videoSource: "screen", // The source of video. If undefined default webcam
          publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
          publishVideo: true, // Whether you want to start publishing with your video enabled or not
          resolution: "640x480", // The resolution of your video
          frameRate: 30, // The frame rate of your video
          insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
          mirror: false, // Whether to mirror your local video or not
          screen: true,
        },
        (error) => {
          if (error) {
            if (error.name == "SCREEN_EXTENSION_NOT_INSTALLED") {
              addFlashMessage({
                type: "error",
                text:
                  error.message ||
                  "Install chrome extention for lecture recording.",
              });
            } else if (error.name == "SCREEN_SHARING_NOT_SUPPORTED") {
              addFlashMessage({
                type: "error",
                text: "Your browser does not support screen sharing",
              });
            } else if (error.name == "SCREEN_EXTENSION_DISABLED") {
              addFlashMessage({
                type: "error",
                text: "You need to enable screen sharing extension.",
              });
            } else if (error.name == "SCREEN_CAPTURE_DENIED") {
              addFlashMessage({
                type: "error",
                text: "You need to choose a window or application to share.",
              });
            }
          } else {
            this.localRecorder = this.OV.initLocalRecorder(
              localRecorder.stream
            );
            this.localRecorder.record();
          }
        }
      );
    } else if (this.localRecorder && this.localRecorder.state == "RECORDING") {
      this.localRecorder.stop().then(() => {
        this.localRecorder.download();
        this.localRecorder.clean();
      });
    } else if (this.localRecorder && this.localRecorder.state == "FINISHED") {
      this.localRecorder.record();
    }
  };

  toggleAlert = (type) => {
    const { alertBox, navTypeAfterSave } = this.state;
    const { alertType } = alertBox;

    let alertMessage = "";
    if (type == "endLecture") {
      alertMessage = "you want to end your session";
    } else if (type == "clearCanvas") {
      alertMessage = "You want to clear your annotations.";
    } else if (type == "saveAnnotation") {
      alertMessage = "You want to save your annotations.";
    } else if (type == "navConfirmation") {
      alertMessage = "Save Your Annotations?";
    }

    if (alertType == "navConfirmation") {
      this._canvas._clear();
      this.navigateSlide(navTypeAfterSave);

      this.setState({ navTypeAfterSave: null });
    }

    this.setState({
      alertBox: {
        showAlert: !this.state.alertBox.showAlert,
        alertType: type,
        alertMessage,
      },
    });
  };

  toggleFlag = (name) => {
    this.setState({ flags: { [name]: !this.state.flags[name] } });
  };

  alertOnOK = () => {
    const { alertType } = this.state.alertBox;
    if (alertType == "endLecture") {
      this.socket.emit("exitLecture", { sessionId: this.state.sessionId });
      this.context.router.push({ pathname: "/" });
    } else if (alertType == "clearCanvas") {
      this._canvas._sketch.clearObjects();
    } else if (alertType == "saveAnnotation") {
      this.saveStudentSlide();
    } else if (alertType == "navConfirmation") {
      this.saveStudentSlide(true);
    }

    this.setState({
      alertBox: {
        showAlert: !this.state.alertBox.showAlert,
        alertType: "",
        alertMessage: "",
      },
    });
  };

  exportUserWorkbook = () => {
    const { exportUserWorkbook, addFlashMessage, location } = this.props;
    const { workbook } = this.state;
    const { _id } = workbook;

    addFlashMessage({
      type: "success",
      text:
        "Your workbook export request is in progress, workbook download will start soon.",
    });

    exportUserWorkbook(_id)
      .then((res) => {
        addFlashMessage({
          type: "success",
          text: res.data.message || "Your workbook exported successfully.",
        });

        window.open(`${HOST}/api/workbook/download/${_id}`);
      })
      .catch((error) => {
        addFlashMessage({
          type: "error",
          text:
            error.response.message ||
            "Oops, something went wrong while fetching data from the server",
        });
      });
  };

  _canvasFunction = (type) => {
    this._canvas[type]();
  };

  saveAnnotation = () => {
    const { saveAnnotation, canvasImageToBase64, addFlashMessage } = this.props;

    const { slideHandler, slides, multiPageDoc } = this.state;
    const { currentSlide } = slideHandler;
    const { current, details } = multiPageDoc;

    const slide = slides[currentSlide];
    if (!slide) return true;

    const { filetype, path, name } = slide;
    const ext = /^.+\.([^.]+)$/.exec(name);
    const extention = ext == null ? "" : ext[1].toLocaleLowerCase();

    let slideType001 = ["image", "jpg", "png", "jpeg"];
    let slideType002 = [
      "excel",
      "word",
      "ppt",
      "notebook",
      "pdf",
      "docx",
      "doc",
      "xlsx",
      "xls",
    ];
    let slideType003 = ["video", "mp4"];

    let drivePath = "";
    if (
      slideType001.indexOf(filetype) > -1 ||
      slideType001.indexOf(extention) > -1
    ) {
      drivePath = path;
    } else if (
      slideType002.indexOf(filetype) > -1 ||
      slideType002.indexOf(extention) > -1
    ) {
      drivePath = details[current].path;
    } else if (
      slideType003.indexOf(filetype) > -1 ||
      slideType003.indexOf(extention) > -1
    ) {
      addFlashMessage({
        type: "error",
        text: "Saving annotation on video is not supported yet.",
      });
      return true;
    } else {
      console.error("Invalide File Type", filetype);
      console.error("Invalide path", path);
      return true;
    }

    let dataURL = this._canvas._sketch.toJSON(["width", "height"]);

    let objects = dataURL.objects;
    canvasImageToBase64({ objects })
      .then((res) => {
        dataURL.objects = res.data.objects;
        this._canvas._sketch.fromJSON(dataURL, () => {
          const base64 = this._canvas._sketch.toDataURL({
            format: "png",
            quality: 0.8,
          });

          let binary = atob(base64.split(",")[1]);
          //Create 8-bit unsigned array
          let array = [];
          let i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }

          let blob = new Blob([new Uint8Array(array)], { type: "image/png" });

          let formData = new FormData();
          formData.append("file", blob, drivePath);
          formData.append("localPath", drivePath);

          saveAnnotation(formData)
            .then(() => {
              this._canvas._clear();
              this._canvas._sketch.setBackgroundFromDataUrl(base64);
              this._canvas.setState({ canRedo: false });

              addFlashMessage({
                type: "success",
                text: "Your annotation on slide has been saved.",
              });
            })
            .catch((error) => {
              console.error(error);
              addFlashMessage({
                type: "error",
                text:
                  "Oops, something went wrong while saving data from the server",
              });
            });
        });
      })
      .catch((error) => {
        console.error(error);
        addFlashMessage({
          type: "error",
          text: "Oops, something went wrong while saving data from the server",
        });
      });
  };

  backToHomeSlide = () => {
    const { slides } = this.state;
    this.setState({
      slideHandler: { ...this.state.slideHandler, currentSlide: 0 },
    });

    this._canvas.slideHandler(slides[0]);
  };

  navigateSlide = (type, index) => {
    const { slideHandler, slides } = this.state;
    const { currentSlide, totalSlides } = slideHandler;

    let slide = slides[currentSlide];
    if (!slide) return true;

    let slideType002 = ["video", "mp4"];
    const { filetype, path, name } = slides[currentSlide];
    const ext = /^.+\.([^.]+)$/.exec(name);
    const extention = ext == null ? "" : ext[1].toLocaleLowerCase();

    if (
      slideType002.indexOf(filetype) > -1 ||
      slideType002.indexOf(extention) > -1
    ) {
      this._canvas._clear();
    } else if (
      this._canvas._sketch.toJSON().objects &&
      this._canvas._sketch.toJSON().objects.length &&
      this._canvas.state.canUndo
    ) {
      this.setState({ navTypeAfterSave: type, navSlideIndexAfterSave: index });
      return this.toggleAlert("navConfirmation");
    }

    this.setState({ isDirtySlide: true });
    switch (type) {
      case "next":
        let nextIndex = currentSlide + 1;
        if (totalSlides > nextIndex) {
          this.setState({
            slideHandler: {
              ...this.state.slideHandler,
              currentSlide: nextIndex,
            },
          });
          this._canvas.slideHandler(slides[nextIndex]);
        } else {
          this.syncWithClass();
        }
        break;
      case "previous":
        let previousIndex = currentSlide - 1;
        if (previousIndex >= 0) {
          this.setState({
            slideHandler: {
              ...this.state.slideHandler,
              currentSlide: previousIndex,
            },
          });
          this._canvas.slideHandler(slides[previousIndex]);
        } else {
          this.syncWithClass();
        }
        break;
      case "jump":
        this.setState({
          slideHandler: {
            ...this.state.slideHandler,
            currentSlide: index || this.state.navSlideIndexAfterSave,
          },
        });
        this._canvas.slideHandler(
          slides[index || this.state.navSlideIndexAfterSave]
        );
        break;
      default:
        console.error("Navigate slide handler invalid", type);
        break;
    }
  };

  getDocDetails = async (path) => {
    const { getDocDetails } = this.props;

    try {
      let result = await getDocDetails(path);
      const { details } = result.data;
      this.setState({
        multiPageDoc: {
          current: -1,
          totalSlides: details.length,
          details,
          next: !!details.length,
          previous: false,
        },
      });
      this.navMultiPage("next");
    } catch (error) {
      addFlashMessage({
        type: "error",
        text: "Oops, something went wrong while extracting your slide deatils",
      });
    }
  };
  navMultiPage = (type) => {
    const { multiPageDoc } = this.state;
    const { current, details } = multiPageDoc;

    switch (type) {
      case "previous":
        this.setState(
          {
            multiPageDoc: { ...this.state.multiPageDoc, current: current - 1 },
          },
          () => {
            this._canvas.slideType001(details[current - 1]);
          }
        );
        break;
      case "next":
        this.setState(
          {
            multiPageDoc: { ...this.state.multiPageDoc, current: current + 1 },
          },
          () => {
            this._canvas.slideType001(details[current + 1]);
          }
        );
        break;
      default:
        this.setState({
          multiPageDoc: { ...this.state.multiPageDoc, current: 0 },
        });
        this._canvas.slideType001(details[0]);
    }
  };

  changeColor = (color) => {
    const { hex } = color;
    this.setState({ lineColor: hex });
    this._canvas.changeColor(hex);
  };

  _selectTool = async (tool) => {
    if (!this._canvas) {
      await Promise.resolve(
        new Promise((resolve) => setTimeout(resolve, 1000))
      );
    }
    if (this.state.tool === tool) {
      this.setState({ tool });
    } else {
      this.setState({ tool });
    }

    this._canvas._selectTool(tool);
  };

  _onBackgroundImageDrop = (accepted) => {
    this._canvas._onBackgroundImageDrop(accepted);
  };
  toggleLiveSearch = () => {
    this.setState({ imgGalleryFlag: !this.state.imgGalleryFlag });
  };
  _toggleFullScreen = () => {
    this._canvas._toggleFullScreen();
  };
  addLiveSearchImage = (url) => {
    this._canvas.addLiveSearchImage(url);
  };

  secToDHMS = (seconds) => {
    // day, h, m and s
    var days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * (24 * 60 * 60);
    var hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * (60 * 60);
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return (
      (0 < days ? days + " day, " : "") +
      (0 < hours ? hours + " h, " : "") +
      minutes +
      "m and " +
      seconds +
      "s"
    );
  };
  toggleSlidePreview = () => {
    this.setState({
      slideHandler: {
        ...this.state.slideHandler,
        preview: !this.state.slideHandler.preview,
      },
    });
  };
  mannageCompare = (e) => {
    const { compareArray } = this.state;
    let index = compareArray.indexOf(e.target.value * 1);

    if (e.target.checked === true) {
      if (index == -1) {
        compareArray.push(e.target.value * 1);
      }
    } else {
      if (index > -1) {
        compareArray.splice(index, 1);
      }
    }
    this.setState({ compareArray });
    console.log("compareArray", compareArray);
  };

  toggleComparePopup = () => {
    this.setState({ comparePopupFlag: !this.state.comparePopupFlag });
  };

  toggleSessionIdPopup = () => {
    this.setState({ showSessionIdModal: !this.state.showSessionIdModal });
  };

  toggleRequestAccess = () => {
    const { email, sessionId, isAccessGranted } = this.state;
    this.socket.emit("requestAccess", {
      email: email,
      sessionId: sessionId,
      type: isAccessGranted ? "revoke" : "grant",
    });
  };

  handleSetting = () => {
    this.setState({ showSetting: !this.state.showSetting });
  };

  syncWithClass = () => {
    const { email, sessionId } = this.state;
    this.socket.emit("syncStudent", {
      email: email,
      sessionId: sessionId,
    });
  };

  saveStudentSlide = (navAfterSave = false) => {
    const { sessionId, navTypeAfterSave } = this.state;
    const { slideHandler } = this.state;
    const { currentSlide } = slideHandler;

    const { canvasImageToBase64, addFlashMessage } = this.props;

    let dataURL = this._canvas._sketch.toJSON(["width", "height"]);
    let objects = dataURL.objects;

    canvasImageToBase64({ objects })
      .then((res) => {
        dataURL.objects = res.data.objects;

        this._canvas._sketch.fromJSON(dataURL, () => {
          const base64Img = this._canvas._sketch.toDataURL({
            format: "png",
            quality: 0.8,
          });

          var element = document.createElement("a");
          element.setAttribute("href", base64Img);
          element.setAttribute(
            "download",
            `${sessionId} - ${currentSlide}.png`
          );

          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          if (navAfterSave) {
            this._canvas._clear();
            this.navigateSlide(navTypeAfterSave);
            this.setState({ navTypeAfterSave: null });
          }
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error(error);
        addFlashMessage({
          type: "error",
          text: "Oops, something went wrong while saving data from the server",
        });
      });
  };

  _showImageUplaoder = () => {
    this.setState({ showImageUplaoder: !this.state.showImageUplaoder }, () => {
      this._selectTool(Tools.Select);
    });
  };
  changeToolbarSize = (event) => {
    const { value } = event.target;
    this.setState(
      { toolbarSize: value == 1 ? "small" : value == 2 ? "md" : "lg" },
      () => {
        localStorage.setItem("toolbarSize", this.state.toolbarSize);
      }
    );
  };

  questionAttempt = (index, option) => {
    const { email, sessionId } = this.state;

    // if (this.state.votingAnswer[index]) { return true; }

    this.setState(
      { votingAnswer: { ...this.state.votingAnswer, [index]: option } },
      () => {
        this.socket.emit("votingAnswer", {
          email: email,
          sessionId: sessionId,
          votingAnswer: this.state.votingAnswer,
        });

        if (this.state.chartData.labels.indexOf(email) == -1) {
          this.setState({ labels: this.state.chartData.labels.push(email) });
        }

        let chartData = this.state.chartData;
        chartData["datasets"][0].label = email;
        chartData["datasets"][0].data[
          this.state.chartData.labels.indexOf(email)
        ] = this.correctAnswers(this.state.votingAnswer);

        this.setState({ chartData });
      }
    );
  };

  correctAnswers = (answers) => {
    let votingQuestions = this.state.votingData.data;
    let nsgCorrectAnswers = 0;
    Object.keys(answers).forEach((key) => {
      if (answers[key] == votingQuestions[key].answer) {
        nsgCorrectAnswers++;
      }
    });
    return nsgCorrectAnswers;
  };

  navHandler = (type) => {
    let { questionIndex, data } = this.state.votingData;
    let nsgQuizIndex = questionIndex;
    if (type == "next" && data[nsgQuizIndex + 1]) {
      nsgQuizIndex++;
    } else if (type == "previous" && data[nsgQuizIndex - 1]) {
      nsgQuizIndex--;
    }
    this.setState({
      votingData: { ...this.state.votingData, questionIndex: nsgQuizIndex },
    });
  };

  render() {
    const {
      workbook,
      alertBox,
      flags,
      slides,
      sessionId,
      slideHandler,
      multiPageDoc,
      groupChat,
      compareArray,
      comparePopupFlag,
      showSessionIdModal,
      tool,
      lineColor,
      imgGalleryFlag,
      publisher,
      requestFromStudent,
      studentList,
      isAccessGranted,
      subscribers,
      lectureToolsFlag,
      isVideoPaused,
      isAudioPaused,
      showSetting,
      studentNavigation,
      showImageUplaoder,
      studentLiveImageSearch,
      studentSettings,
      toolbarSize,
      votingData,
      votingAnswer,
      email,
      chartData,
      isLoading,
      videoCall,
      videoDrawer,
    } = this.state;
    const { currentSlide, preview } = slideHandler;
    const { mainTools } = flags;
    const { name, subject } = workbook;
    const { showAlert, alertMessage, alertType } = alertBox;

    const groupChatMessages = map(groupChat, (value, key) => (
      <div key={key} className={cx(s["at-clientmessage"])}>
        <div className={cx(s["at-messagebox"])}>
          <figure className={cx(s["at-userimg"])}>
            <img src="images/user-placeholder.jpg" alt="image description" />
          </figure>
          <div className={cx(s["at-chatcontent"])}>
            {ValidURL(value.message) ? (
              <p>
                <a href={value.message} target="_blank">
                  click to view attachment
                </a>{" "}
              </p>
            ) : (
              <p>{value.message}</p>
            )}
          </div>
        </div>
        <div className={cx(s["at-messagestatus"])}>
          <span className={cx(s["at-emailadd"])}>{value.username}</span>
        </div>
      </div>
    ));

    let mainToolStyle = {
      ...(!studentSettings && {
        maxWidth: 330,
        ...(mainTools && { left: -280 }),
      }),
    };

    return (
      <Layout>
        <Layout.Content>
          <Spin spinning={isLoading} size="large">
            {this.state.pause ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 100,
                  background: "white",
                }}
              >
                <div
                  style={{
                    margin: 0,
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Space align="center" direction="vertical">
                    <PauseCircleOutlined
                      style={{
                        fontSize: 150,
                      }}
                    />

                    <> Lecture Pause By Teacher</>
                  </Space>
                </div>
              </div>
            ) : null}

            <Topbar
              workbookTitle={name || "Untitled Lecture"}
              sessionId={sessionId}
              syncWithClass={this.syncWithClass}
              endSession={this.endSession}
              saveAnnotation={this.saveStudentSlide}
              removeSlide={this.removeSlide}
              previewSlide={this.previewSlide}
              preview={!preview}
              toggleSlidePreview={this.toggleSlidePreview}
              slides={slides}
              onSortEnd={this.onSortEnd}
              backToHomeSlide={this.backToHomeSlide}
              navigateSlide={this.navigateSlide}
              selectTool={this._selectTool}
              toggleLiveSearch={this.toggleLiveSearch}
              toggleFullScreen={this._toggleFullScreen}
              toolsPosition={this.state.toolsPosition}
              pauseStudentScreen={this.pauseStudentScreen}
              togglePdfNameAlert={this.exportUserWorkbook}
              toggleStudentNavigation={this.toggleStudentNavigation}
              studentNavigation={studentNavigation}
              isDirtySlide={this.state.isDirtySlide}
              isAccessGranted={isAccessGranted}
              toggleRequestAccess={this.toggleRequestAccess}
              exit={this.state.exit}
              isAudioPaused={isAudioPaused}
              toggleMic={this.toggleMic}
              // this._selectTool.bind(this, Tools.Select)
            />

            <div className="gx-customizer-option">
              <Button type="primary" onClick={() => this.handleSetting()}>
                <i className="icon icon-setting gx-d-block" />
              </Button>
            </div>

            {studentNavigation && (
              <Tooltip placement="left" title={"Show Lecture Slides"}>
                <div style={{ top: 100 }} className="gx-customizer-option">
                  <Button type="primary" onClick={this.toggleSlidePreview}>
                    <FundProjectionScreenOutlined className="icon" />
                  </Button>
                </div>
              </Tooltip>
            )}

            {videoCall && (
              <Tooltip placement="left" title={"Show Lecture Slides"}>
                <div style={{ top: 200 }} className="gx-customizer-option">
                  <Button
                    type="primary"
                    onClick={() => this.setState({ videoDrawer: !videoDrawer })}
                  >
                    <UsergroupAddOutlined className="icon" />
                  </Button>
                </div>
              </Tooltip>
            )}

            {videoCall && (
              <CastPopup
                visible={videoDrawer}
                toggleDrawer={() =>
                  this.setState({ videoDrawer: !videoDrawer })
                }
                sessionId={sessionId}
                videoCall={videoCall}
                username={email}
              />
            )}

            {/*<LectureTopBar*/}
            {/*  name={name || "Untitled Lecture"}*/}
            {/*  subject={subject || "Untitled Lecture"}*/}
            {/*  toggleRequestAccess={this.toggleRequestAccess}*/}
            {/*  restartLecture={this.restartLecture}*/}
            {/*  requestFromStudent={requestFromStudent}*/}
            {/*  isAccessGranted={isAccessGranted}*/}
            {/*  studentList={studentList}*/}
            {/*  manageAccess={this._grantAcess}*/}
            {/*  sessionId={sessionId}*/}
            {/*  subscribers={subscribers}*/}
            {/*  mannageCompare={this.mannageCompare}*/}
            {/*  compareArray={compareArray}*/}
            {/*  toggleComparePopup={this.toggleComparePopup}*/}
            {/*  comparePopupFlag={comparePopupFlag}*/}
            {/*  toggleSessionIdPopup={this.toggleSessionIdPopup}*/}
            {/*  exit={this.state.exit}*/}
            {/*  isAudioPaused={isAudioPaused}*/}
            {/*  toggleMic={this.toggleMic}*/}
            {/*  isDirtySlide={this.state.isDirtySlide}*/}
            {/*  syncWithClass={this.syncWithClass}*/}
            {/*/>*/}

            <PopupTypeTwo
              onToggle={() => {}}
              isOpen={
                this.state.startActivityOnly ||
                (votingData.data &&
                  votingData.data.length &&
                  votingData.votingStudentList &&
                  votingData.votingStudentList.indexOf(email) > -1)
              }
              title={"Voting Activity"}
              onOK={""}
              onCancel={() => {}}
              size={"md"}
            >
              {(this.state.startActivityOnly &&
                !(votingData.data && votingData.data.length)) ||
              (votingData.votingStudentList &&
                !(votingData.votingStudentList.indexOf(email) > -1)) ? (
                <div
                  className={s["nsg-votingslideimg"]}
                  dangerouslySetInnerHTML={{
                    __html:
                      "<h3>Activity will start soon, please wait for your teacher/instructor to start the session</h3>",
                  }}
                />
              ) : (
                votingData.votingStudentList &&
                votingData.votingStudentList.indexOf(email) > -1 && (
                  <div
                    className={cx(s["nsg-bgWhite"], {
                      [s["nsg-votingPopup"]]: true,
                    })}
                  >
                    {/* <a onClick={this.toggleVotingPopup} className={s["nsg-comparecastclonsebtn"]}><i className="fa fa-times"></i></a> */}
                    {votingData.votingDrivenType == "Self Paced" &&
                      !this.state.showResults &&
                      votingData.data &&
                      votingData.data.length &&
                      votingData.data[votingData.questionIndex] && (
                        <Fragment>
                          {(votingAnswer[votingData.questionIndex] ||
                            !(
                              votingData.data &&
                              votingData.data.length &&
                              votingData.data[votingData.questionIndex] &&
                              votingData.data[votingData.questionIndex].options
                                .length - 1
                            )) && (
                            <a
                              onClick={this.navHandler.bind(this, "next")}
                              className={cx(
                                s["nsg-comparecastclonsebtn"],
                                s["nsg-votingNext"]
                              )}
                            >
                              <i className="fa fa-angle-right" />
                            </a>
                          )}

                          {votingData.questionIndex >= 1 && (
                            <a
                              onClick={this.navHandler.bind(this, "previous")}
                              className={cx(
                                s["nsg-comparecastclonsebtn"],
                                s["nsg-votingPrevious"]
                              )}
                            >
                              <i className="fa fa-angle-left" />
                            </a>
                          )}
                        </Fragment>
                      )}
                    <div className={s["nsg-votingImageHolder"]}>
                      {!this.state.showResults &&
                      votingData.data &&
                      votingData.data.length &&
                      votingData.data[votingData.questionIndex] ? (
                        <Fragment>
                          {votingData.data &&
                          votingData.data[votingData.questionIndex].type ==
                            "html" ? (
                            <div
                              className={s["nsg-votingslideimg"]}
                              dangerouslySetInnerHTML={{
                                __html:
                                  votingData.data[votingData.questionIndex]
                                    .question,
                              }}
                            />
                          ) : votingData.data &&
                            votingData.data[votingData.questionIndex].type ==
                              "image" ? (
                            <img
                              className={s["nsg-votingslideimg"]}
                              src={`${HOST}/${
                                votingData.data[votingData.questionIndex]
                                  .question
                              }`}
                            />
                          ) : null}

                          {votingData.data &&
                          !votingData.data[votingData.questionIndex].options ? (
                            <h3>
                              {votingData.data &&
                                votingData.data[votingData.questionIndex]
                                  .message}
                            </h3>
                          ) : votingData.data ? (
                            votingData.data[
                              votingData.questionIndex
                            ].options.map((option) => {
                              return (
                                <button
                                  key={option}
                                  value={option}
                                  onClick={this.questionAttempt.bind(
                                    this,
                                    votingData.questionIndex,
                                    option
                                  )}
                                  className={cx(s["nsg-question_option"], {
                                    [s["nsg-bgGreen"]]:
                                      votingAnswer[votingData.questionIndex] ==
                                      option,
                                  })}
                                >
                                  {option}
                                </button>
                              );
                            })
                          ) : null}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <h3>Reporting and Results</h3>
                          <em>
                            Total:{" "}
                            {votingData.data
                              ? votingData.data.length - 1
                              : null}
                          </em>

                          <div className={s["at-folderholder"]}>
                            <Bar
                              data={chartData}
                              options={{
                                scales: {
                                  yAxes: [
                                    {
                                      display: true,
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Correct Answers",
                                      },
                                      ticks: {
                                        min: 0,
                                        max: votingData.data
                                          ? votingData.data.length - 1
                                          : 10,

                                        // forces step size to be 5 units
                                        stepSize: 1,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                )
              )}
            </PopupTypeTwo>

            <PopupTypeTwo
              onToggle={this.toggleAlert}
              isOpen={showAlert}
              title={alertMessage}
              onOK={this.alertOnOK}
              onCancel={this.toggleAlert}
              popupIcon={""}
            >
              <h3>Are you sure?</h3>
              <em>{alertMessage}</em>
              <div className="at-btnarea">
                <a
                  onClick={this.alertOnOK}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  Yes
                </a>
                <a
                  onClick={this.toggleAlert}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  Cancel
                </a>
              </div>
            </PopupTypeTwo>

            <PopupTypeOne
              onToggle={this.toggleSessionIdPopup}
              isOpen={showSessionIdModal}
              title={"Session  ID"}
            >
              <h1 className={s["at-sessionId"]}>{sessionId}</h1>

              <a
                onClick={this.toggleSessionIdPopup}
                href="javascript:void(0);"
                className={cx("btn at-themebtn")}
              >
                Cancel
              </a>
            </PopupTypeOne>

            <main
              className={cx(["at-main"], s["at-haslayout"], s["at-bgmain"])}
            >
              <GallerySearch
                addToSlide={this.addLiveSearchImage}
                toggleLiveSearch={this.toggleLiveSearch}
                isOpen={imgGalleryFlag}
              />

              {lectureToolsFlag && this.socket ? (
                <LectureTools
                  socket={this.socket}
                  sessionId={sessionId}
                  isAccessGranted={isAccessGranted}
                  onRef={(lt) => (this._lectureTools = lt)}
                />
              ) : null}

              {this.socket ? (
                <GroupChat socket={this.socket} sessionId={sessionId} />
              ) : null}

              {studentNavigation ? (
                <Draggable handle=".dragable">
                  <div
                    className={cx(
                      s["at-toolsholder"],
                      {
                        [s["at-toolsholder-nsg-small"]]: toolbarSize == "small",
                      },
                      { [s["at-toolsholder-nsg-md"]]: toolbarSize == "md" }
                    )}
                  >
                    <div className={cx("dragable", s["at-dragable"])} />
                    <ul className={s["at-tools"]}>
                      <li
                        onClick={this._selectTool.bind(this, Tools.Select)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.Select,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <SelectOutlined />
                        </a>
                      </li>

                      <Popover
                        placement="right"
                        title={null}
                        content={
                          <Fragment>
                            <CirclePicker
                              colors={[
                                "#000000",
                                "#f44336",
                                "#e91e63",
                                "#9c27b0",
                                "#673ab7",
                                "#3f51b5",
                                "#2196f3",
                                "#03a9f4",
                                "#00bcd4",
                                "#009688",
                                "#4caf50",
                                "#8bc34a",
                                "#cddc39",
                                "#ffeb3b",
                                "#ffc107",
                                "#ff9800",
                                "#ff5722",
                                "#795548",
                              ]}
                              color={this.state.lineColor}
                              onChangeComplete={(color) =>
                                this.setState({ lineColor: color.hex })
                              }
                            />

                            <Slider
                              defaultValue={this.state.lineWidth}
                              min={3}
                              max={50}
                              onChange={(value) =>
                                this.setState({ lineWidth: value })
                              }
                              marks={{
                                25: {
                                  style: {
                                    color: "#02a2de",
                                  },
                                  label: <strong>Thickness</strong>,
                                },
                              }}
                            />
                          </Fragment>
                        }
                        trigger={["click"]}
                      >
                        <li
                          onClick={this._selectTool.bind(this, Tools.Pencil)}
                          className={cx({
                            [s["at-active"]]: tool == Tools.Pencil,
                          })}
                        >
                          <a href="javascript:void(0);">
                            <HighlightOutlined />
                          </a>
                        </li>
                      </Popover>

                      <Popover
                        placement="right"
                        title={null}
                        content={
                          <Fragment>
                            <CirclePicker
                              colors={[
                                "#000000",
                                "#f44336",
                                "#e91e63",
                                "#9c27b0",
                                "#673ab7",
                                "#3f51b5",
                                "#2196f3",
                                "#03a9f4",
                                "#00bcd4",
                                "#009688",
                                "#4caf50",
                                "#8bc34a",
                                "#cddc39",
                                "#ffeb3b",
                                "#ffc107",
                                "#ff9800",
                                "#ff5722",
                                "#795548",
                              ]}
                              color={this.state.lineColor}
                              onChangeComplete={(color) =>
                                this.setState({ lineColor: color.hex })
                              }
                            />

                            <Slider
                              defaultValue={this.state.lineWidth}
                              min={3}
                              max={50}
                              marks={{
                                25: {
                                  style: {
                                    color: "#02a2de",
                                  },
                                  label: <strong>Thickness</strong>,
                                },
                              }}
                              onChange={(value) =>
                                this.setState({ lineWidth: value })
                              }
                            />

                            <Space>
                              <Tooltip
                                placement="bottom"
                                title={<span>Rectangle</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Rectangle
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/rect-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Circle</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Circle
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/circ-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Line</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Line
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/line-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Arrow</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Arrow
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/arrow-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>
                            </Space>
                          </Fragment>
                        }
                        trigger={["click"]}
                      >
                        <li>
                          <a href="javascript:void(0);">
                            <CloudIcon type="icon-shapes" />
                          </a>
                        </li>
                      </Popover>

                      <li onClick={this._showImageUplaoder}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-gallery" />
                        </a>
                        {showImageUplaoder ? (
                          <ul className={s["at-searchimgholder"]}>
                            <li>
                              <label
                                onClick={() => {
                                  this.dropzoneRef.open();
                                }}
                              >
                                <img
                                  src="images/upload-img-icon.png"
                                  alt="image description"
                                />
                                <span>upload image</span>
                              </label>
                              <DropZone
                                ref="dropzone"
                                accept="image/*"
                                multiple={true}
                                style={{ opacity: 0, height: 40 }}
                                onDrop={this._onBackgroundImageDrop}
                                ref={(c) => (this.dropzoneRef = c)}
                              />
                            </li>
                            {studentLiveImageSearch && (
                              <li>
                                <label onClick={this.toggleLiveSearch}>
                                  <i className="fa fa-search" />
                                  <span>Live search</span>
                                </label>
                              </li>
                            )}
                          </ul>
                        ) : null}
                      </li>
                      <li
                        onClick={this._selectTool.bind(this, Tools.IText)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.IText,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <FontSizeOutlined />
                        </a>
                      </li>

                      <li
                        onClick={this._selectTool.bind(this, Tools.Erase)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.Erase,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-erase" />
                        </a>
                      </li>

                      <li onClick={this.toggleAlert.bind(this, "clearCanvas")}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-erase-all" />
                        </a>
                      </li>
                    </ul>
                    <ul className={s["at-btnfullscreen"]}>
                      <li
                        onClick={this._selectTool.bind(this, Tools.Pan)}
                        className={cx({ [s["at-active"]]: tool == Tools.Pan })}
                      >
                        <a href="javascript:void(0);">
                          <DragOutlined />
                        </a>
                      </li>

                      <li onClick={this._toggleFullScreen}>
                        <a href="javascript:void(0);">
                          <ExpandOutlined />
                        </a>
                      </li>

                      <li onClick={() => this._canvas._sketch.bringToFront()}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-send-to-front" />
                        </a>
                      </li>
                      <li onClick={() => this._canvas._sketch.sendToBack()}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-send-to-back" />
                        </a>
                      </li>

                      {/*<li onClick={() => this._canvas._sketch.zoom("zoomIn")}>*/}
                      {/*  <a href="javascript:void(0);">*/}
                      {/*    <img*/}
                      {/*      src="images/tools-icons/icon-08.png"*/}
                      {/*      alt="image description"*/}
                      {/*    />*/}
                      {/*  </a>*/}
                      {/*</li>*/}
                      {/*<li onClick={() => this._canvas._sketch.zoom("zoomOut")}>*/}
                      {/*  <a href="javascript:void(0);">*/}
                      {/*    <img*/}
                      {/*      src="images/tools-icons/icon-09.png"*/}
                      {/*      alt="image description"*/}
                      {/*    />*/}
                      {/*  </a>*/}
                      {/*</li>*/}
                    </ul>
                    <div className={cx("dragable", s["at-dragable"])} />
                  </div>
                </Draggable>
              ) : null}

              <Draggable handle={`.teachervideodraghandler`}>
                <div
                  ref={(tsc) => (this.teacherStreamContainer = tsc)}
                  className={cx(s["at-videocallscreen"], {
                    [s["at-openvideoscreen"]]: publisher,
                  })}
                >
                  <figure className={cx(s["at-videoimg"])}>
                    <video
                      className="teachervideodraghandler"
                      ref={(v) => (this.teacherStream = v)}
                    />
                    <figcaption>
                      <ul className={cx(s["at-callactions"])}>
                        <li
                          ref={(tsrh) =>
                            (this.teacherStreamResizHandler = tsrh)
                          }
                        >
                          <a className={s["nsg-resizeHandler"]}>
                            <i className="fa fa-expand" />
                          </a>
                        </li>
                      </ul>
                    </figcaption>
                  </figure>
                </div>
              </Draggable>

              {showSetting && (
                <Settings
                  lineColor={this.state.lineColor}
                  lineWidth={this.state.lineWidth}
                  fillColor={this.state.fillColor}
                  fontSize={this.state.fontSize}
                  fontName={this.state.fontName}
                  toolsPosition={this.state.toolsPosition}
                  liveSearch={this.state.studentLiveImageSearch}
                  toolbarSize={this.state.toolbarSize}
                  studentSettings={this.state.studentSettings}
                  onChangeSettings={(property, value) => {
                    this.setState({ [property]: value });
                  }}
                  changeAndEmitSettings={(property, value, sev) => {
                    this.setState({ [property]: value }, () => {
                      this.socket.emit(sev, {
                        [property]: value,
                        sessionId: this.state.sessionId,
                      });
                    });
                  }}
                  zoomHandler={(property) =>
                    this._canvas._sketch.zoom(property)
                  }
                  onClose={() => this.handleSetting()}
                />
              )}

              <div style={{ margin: 0 }} className="gx-app-module">
                <div style={{ maxWidth: "100%" }} className="gx-module-box">
                  <div className="gx-module-box-content">
                    <LectureCanvas
                      onRef={(c) => (this._canvas = c)}
                      slides={slides}
                      lineColor={this.state.lineColor}
                      lineWidth={this.state.lineWidth}
                      fillColor={this.state.fillColor}
                      fontSize={this.state.fontSize}
                      fontName={this.state.fontName}
                      sessionId={sessionId}
                      currentSlide={currentSlide}
                      getDocDetails={this.getDocDetails}
                      multiPageDoc={multiPageDoc}
                      lineColor={lineColor}
                      _canvasToImage={this._canvasToImage}
                      showSetting={showSetting}
                      handleSetting={this.handleSetting}
                      canvasObjectModified={this.canvasObjectModified}
                      updateTool={this._selectTool}
                      onChangToolbarSettings={this.changeToolbarSize}
                      toolbarSize={toolbarSize}
                      createdBy={email}
                    />
                    {studentNavigation ? (
                      <ul
                        className={cx(s["at-btnpdf"], {
                          [s["hide"]]:
                            slides.length && slides[currentSlide]
                              ? [
                                  "excel",
                                  "word",
                                  "ppt",
                                  "notebook",
                                  "pdf",
                                  "docx",
                                  "doc",
                                  "xlsx",
                                  "xls",
                                ].indexOf(slides[currentSlide].filetype) == -1
                              : true,
                        })}
                      >
                        <li>
                          <button
                            onClick={this.navMultiPage.bind(this, "previous")}
                            disabled={multiPageDoc.current <= 0}
                          >
                            Previous
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={this.navMultiPage.bind(this, "next")}
                            disabled={
                              multiPageDoc.totalSlides == multiPageDoc.current
                            }
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    ) : null}

                    <div style={{ position: "fixed", bottom: 30, left: 30 }}>
                      <Button.Group>
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomIn")}
                          icon={<ZoomInOutlined />}
                        />
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomReset")}
                          icon={<SearchOutlined />}
                        />
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomOut")}
                          icon={<ZoomOutOutlined />}
                        />
                      </Button.Group>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </Spin>
        </Layout.Content>
        {/* <Layout.Sider className="gx-mediasoup-holder">
					<iframe
						width="100%"
						height="100%"
						style={{ border: 0 }}
						allow="camera; microphone"
						src={`${process.env.REACT_APP_MEET_HOST}/${sessionId}?roomId=${sessionId}&username=${email}`}
					/>
				</Layout.Sider> */}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  getUserProfile,
  getSessionId,
  getWorkbookDetails,
  addSlideToEnd,
  exportUserWorkbook,
  getDocDetails,
  saveAnnotation,
  canvasImageToBase64,
  getToken,
  addFlashMessage,
})(LecturePage);
