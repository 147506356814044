import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ToolHolder from "../holder/ToolHolder";
import s from "./Protractor.module.css";

class Protractor extends Component {
  static propTypes = {
    closeTool: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isRotating: false,
      isMoving: false,
      isScaling: false,
      isScalingX: false,
      isScalingY: false,

      boxScaleX: 1.0,
      boxScaleY: 1.0,

      boxX: 200,
      boxY: 200,

      lastX: 0,
      lastY: 0,

      boxWidth: 0,
      boxHeight: 0,
      boxLeft: 0,
      boxTop: 0,

      initialAngle: 0,
      lastAngle: 0,
      currentAngle: 0,
      origin: {
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount = () => {
    this.handler1.addEventListener("mousedown", this.handlerMouseDown);
    this.handler2.addEventListener("mousedown", this.handlerMouseDown);
    this.handler3.addEventListener("mousedown", this.handlerMouseDown);
    this.handler4.addEventListener("mousedown", this.handlerMouseDown);

    let _box$getBoundingClien = this.box.getBoundingClientRect();
    let boxWidth = _box$getBoundingClien.width,
      boxHeight = _box$getBoundingClien.height,
      boxLeft = _box$getBoundingClien.left,
      boxTop = _box$getBoundingClien.top;

    this.setState({
      origin: {
        ...this.state.origin,
        x: boxLeft + (boxWidth >> 1),
        y: (boxTop + (boxHeight >> 1)) * 2,
      },
    });
    this.box.addEventListener("mousedown", this.boxMouseDown);
  };

  componentWillUnmount = () => {
    this.handler1.removeEventListener("mousedown", this.handlerMouseDown);
    this.handler2.removeEventListener("mousedown", this.handlerMouseDown);
    this.handler3.removeEventListener("mousedown", this.handlerMouseDown);
    this.handler4.removeEventListener("mousedown", this.handlerMouseDown);

    this.box.removeEventListener("mousedown", this.boxMouseDown);
  };

  isScaleHandlerX = (handler) => {
    return (
      handler.classList.contains(s["sl"]) || handler.classList.contains(s["sr"])
    );
  };

  isScaleHandlerY = (handler) => {
    return (
      handler.classList.contains(s["st"]) || handler.classList.contains(s["sb"])
    );
  };

  isScaleHandler = (handler) => {
    return (
      handler.classList.contains(s["sl"]) ||
      handler.classList.contains(s["sr"]) ||
      handler.classList.contains(s["st"]) ||
      handler.classList.contains(s["sb"])
    );
  };

  isRotationHandler = (handler) => {
    return (
      handler.classList.contains(s["lt"]) ||
      handler.classList.contains(s["rt"]) ||
      handler.classList.contains(s["lb"]) ||
      handler.classList.contains(s["rb"])
    );
  };

  boxMouseDown = (e) => {
    let { isRotating, isScaling } = this.state;
    if (e.button === 0 && isRotating === false && isScaling === false) {
      document.addEventListener("mousemove", this.boxMouseMove);
      document.addEventListener("mouseup", this.boxMouseUp);

      this.setState({ lastX: e.clientX, lastY: e.clientY, isMoving: true });
    }
  };

  boxMouseMove = (e) => {
    let {
      boxX,
      boxY,
      lastX,
      lastY,
      currentAngle,
      boxScaleX,
      boxScaleY,
    } = this.state;
    boxX += e.clientX - lastX;
    boxY += e.clientY - lastY;

    lastX = e.clientX;
    lastY = e.clientY;
    this.setState({ boxX, boxY, lastX, lastY });

    this.box.style.transform =
      "transform-origin: 100% 50%; translate(" +
      boxX +
      "px," +
      boxY +
      "px) rotate(" +
      Math.round((currentAngle * 180) / Math.PI) +
      "deg) scale(" +
      boxScaleX +
      "," +
      boxScaleY +
      ")";
  };

  boxMouseUp = (e) => {
    if (e.button === 0) {
      document.removeEventListener("mousemove", this.boxMouseMove);
      document.removeEventListener("mouseup", this.boxMouseUp);
      this.setState({ isMoving: false });
    }
  };

  handlerMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let { isMoving, origin } = this.state;

    if (e.button === 0 && isMoving === false) {
      var _box$getBoundingClien2 = this.box.getBoundingClientRect(),
        _boxWidth = _box$getBoundingClien2.width,
        _boxHeight = _box$getBoundingClien2.height,
        _boxLeft = _box$getBoundingClien2.left,
        _boxTop = _box$getBoundingClien2.top;

      let isScalingX = this.isScaleHandlerX(e.currentTarget);
      let isScalingY = this.isScaleHandlerY(e.currentTarget);

      if (isScalingX) {
        let lastX = e.clientX;
        this.setState({ lastX });
      } else if (isScalingY) {
        let lastY = e.clientY;
        this.setState({ lastY });
      }

      let initialAngle = Math.atan2(e.clientY - origin.y, e.clientX - origin.x);

      this.setState({
        initialAngle,
        isScalingX,
        isScalingY,
        isScaling: this.isScaleHandler(e.currentTarget),
        isRotating: this.isRotationHandler(e.currentTarget),
        origin: {
          ...this.state.origin,
          x: _boxLeft + (_boxWidth >> 1),
          y: _boxTop + (_boxHeight >> 1),
        },
      });

      document.addEventListener("mousemove", this.handlerMouseMove);
      document.addEventListener("mouseup", this.handlerMouseUp);
    }
  };

  handlerMouseMove = (e) => {
    let {
      currentAngle,
      isScaling,
      isScalingX,
      isScalingY,
      isRotating,
      origin,
      initialAngle,
      lastX,
      lastY,
      lastAngle,
      boxX,
      boxY,
      boxScaleX,
      boxScaleY,
    } = this.state;

    if (isRotating) {
      var angleToOrigin = Math.atan2(
        e.clientY - origin.y,
        e.clientX - origin.x
      );
      var angle = angleToOrigin - initialAngle;
      currentAngle = angle + lastAngle;

      this.setState({ currentAngle });
      this.box.style.transform =
        "translate(" +
        boxX +
        "px," +
        boxY +
        "px) rotate(" +
        Math.round((currentAngle * 180) / Math.PI) +
        "deg) scale(" +
        boxScaleX +
        "," +
        boxScaleY +
        ")";
    } else if (isScaling) {
      if (isScalingX) {
        boxScaleX -= (e.clientX - lastX) / 200;
        lastX = e.clientX;

        this.setState({ boxScaleX, lastX });
      } else if (isScalingY) {
        boxScaleY -= (e.clientY - lastY) / 200;
        lastY = e.clientY;

        this.setState({ boxScaleY, lastY });
      }
      this.box.style.transform =
        "translate(" +
        boxX +
        "px," +
        boxY +
        "px) rotate(" +
        Math.round((currentAngle * 180) / Math.PI) +
        "deg) scale(" +
        boxScaleX +
        "," +
        boxScaleY +
        ")";
    }
  };

  handlerMouseUp = (e) => {
    let { lastAngle, currentAngle, isRotating, isScaling } = this.state;
    if (e.button === 0) {
      document.removeEventListener("mousemove", this.handlerMouseMove);
      document.removeEventListener("mouseup", this.handlerMouseUp);

      lastAngle = currentAngle;
      if (isRotating === true) {
        isRotating = false;
      } else if (isScaling === true) {
        isScaling = false;
      }
      this.setState({ lastAngle, isRotating, isScaling });
    }
  };

  render() {
    let { closeTool } = this.props;
    return (
      <div ref={(b) => (this.box = b)} className={s["box"]}>
        {/* <img src="images/protractor.png" /> */}
        <a
          onClick={closeTool.bind(this, "Protractor")}
          className={s["at-btnclosetool"]}
        >
          <i className="fa fa-times"></i>
        </a>

        <div
          ref={(h) => (this.handler1 = h)}
          className={cx(s["handler"], s["lt"])}
        ></div>
        <div
          ref={(h) => (this.handler2 = h)}
          className={cx(s["handler"], s["rt"])}
        ></div>
        <div
          ref={(h) => (this.handler3 = h)}
          className={cx(s["handler"], s["lb"])}
        ></div>
        <div
          ref={(h) => (this.handler4 = h)}
          className={cx(s["handler"], s["rb"])}
        >
          {" "}
        </div>
      </div>
    );
  }
}

export default Protractor;
