import React from "react";
import { Link } from "react-router";
import { Drawer, Layout, Divider } from "antd";
import CustomScrollbars from "../../utils/CustomScrollbars";
import _ from "lodash";

const { Sider } = Layout;

const SidebarLogo = () => {
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        <img alt="lo" src={"images/touchit-logo.png"} />
      </Link>
    </div>
  );
};

const CastPopup = ({
  visible,
  toggleDrawer,
  sessionId,
  username,
  videoCall,
  toggleVideoCall,
}) => {
  return (
    <Sider
      className={`gx-app-sidebar gx-collapsed-sidebar`}
      trigger={null}
      collapsed={false}
      collapsible
    >
      <Drawer
        className={`gx-drawer-sidebar`}
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={visible}
      >
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Divider />
            {videoCall && (
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allow="camera; microphone"
                src={`${process.env.REACT_APP_MEET_HOST}/${sessionId}?roomId=${sessionId}&username=${username}&type=lecture`}
              />
            )}
          </CustomScrollbars>
        </div>
      </Drawer>
    </Sider>
  );
};

export default CastPopup;
