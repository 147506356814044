import axios from "axios";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import jwtDecode from "jwt-decode";
import { SET_CURRENT_USER } from "./types";

export function setCurrentUser(user) {
  return { type: SET_CURRENT_USER, user };
}

export function decodeTokenSetCurrentUser(token) {
  return (dispatch) => {
    localStorage.setItem("jwtToken", token);
    setAuthorizationToken(token);
    dispatch(setCurrentUser(jwtDecode(token)));
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem("jwtToken");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export function login(data) {
  return (dispatch) =>
    axios.post("/api/auth/login-via-local", data).then((res) => {
      const token = res.data.token;
      localStorage.setItem("jwtToken", token);
      setAuthorizationToken(token);
      dispatch(setCurrentUser(jwtDecode(token)));
    });
}

export function resetPassword(data) {
  return (dispatch) => axios.post("/api/auth/resetPassword", data);
}

export function isUserExists(identifier) {
  return (dispatch) => axios.get(`/api/auth/${identifier}`);
}

export function refreshAuthToken() {
  return (dispatch) =>
    axios.get(`/api/auth/refresh-token`).then((res) => {
      localStorage.removeItem("jwtToken");

      const token = res.data.token;
      localStorage.setItem("jwtToken", token);
      setAuthorizationToken(token);
      dispatch(setCurrentUser(jwtDecode(token)));
    });
}
