import axios from "axios";

export function getUserProfile() {
  return (dispatch) => axios.get("/api/users");
}

export function updateUserProfile(data) {
  return (dispatch) => axios.post("/api/users/profile", data);
}

export function updateUserPhoto(data) {
  return (dispatch) => axios.post("/api/users/profile-photo", data);
}
