import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ToolHolder from "../holder";
import s from "./StopWatch.module.css";

let anim = null;

let startTime = null;
let pausedTime = 0;
let elapsedTime = 0;
const maxDashoffset = -90;

// put the left 0 if < 10
const getFullValue = (val) => {
  return val < 10 ? "0" + val : val;
};

const getFullMils = (val) => {
  if (val < 10) return "00" + val;
  if (val < 100) return "0" + val;

  return val;
};

window.cancelRequestAnimFrame = (function () {
  return (
    window.cancelAnimationFrame ||
    window.webkitCancelRequestAnimationFrame ||
    window.mozCancelRequestAnimationFrame ||
    window.oCancelRequestAnimationFrame ||
    window.msCancelRequestAnimationFrame ||
    clearTimeout
  );
})();

window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (/* function */ callback, /* DOMElement */ element) {
      return window.setTimeout(callback, 1000 / 60);
    }
  );
})();

class StopWatch extends Component {
  static propTypes = {
    closeTool: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isRunning: false,
      time: `00:00:000`,
      startBtnText: "Start",
      pointerTransform: `translate(-50%, -100%) rotate(0)`,
    };
  }

  componentDidMount = () => {
    const { socket, sessionId } = this.props;

    socket.on("teacher:stopwatch", (data) => {
      this.setState(data.stopwatch, () => {
        if (this.state.isRunning) {
          anim = window.requestAnimFrame(this.startCounter);
          this.setState({ isRunning: true, startBtnText: "Pause" });
        } else {
          this.setState({ isRunning: false, startBtnText: "Continue" });
        }
      });
    });
  };

  mobileAndTabletcheck = () => {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  startCounter = (timestamp) => {
    if (!startTime) startTime = timestamp;
    const { isRunning } = this.state;

    if (isRunning) {
      elapsedTime = timestamp - startTime - pausedTime;

      let dat = new Date(elapsedTime);
      let mins = dat.getMinutes();
      let secs = dat.getSeconds();
      let mils = dat.getMilliseconds();

      let angleBetweenSecs = 360 / 60;
      let angle =
        360 * mins + angleBetweenSecs * secs + (angleBetweenSecs / 1000) * mils;

      mins = getFullValue(mins);
      secs = getFullValue(secs);
      mils = getFullMils(mils);

      this.setState({
        time: `${mins}:${secs}:${mils}`,
        pointerTransform: `translate(-50%, -100%) rotate(${angle}deg)`,
      });

      // only if not mobile (poor performance)
      if (!this.mobileAndTabletcheck()) {
        let svgAngle = -90 + angle;
        let dashoffsetPerAngle = 1.5;
        this.svgEl.style.transform = `translate(-50%, -50%) rotate(${svgAngle}deg)`;

        let newOffset = -135 + (angle * 0.75) / 2;
        if (newOffset <= maxDashoffset) {
          this.svgEllipse.style.strokeDashoffset = newOffset + "%";
        }
      }
    } else {
      pausedTime = timestamp - startTime - elapsedTime;
    }

    anim = window.requestAnimFrame(this.startCounter);
  };

  startStopWatch = () => {
    // if not running
    const { isRunning } = this.state;
    const { socket, sessionId, isAccessGranted } = this.props;

    if (!isRunning) {
      anim = window.requestAnimFrame(this.startCounter);
      this.setState({ isRunning: true, startBtnText: "Pause" }, () => {
        if (isAccessGranted) {
          socket.emit("student:stopwatch", {
            sessionId: sessionId,
            stopwatch: this.state,
          });
        }
      });
    } else {
      this.setState({ isRunning: false, startBtnText: "Continue" }, () => {
        if (isAccessGranted) {
          socket.emit("student:stopwatch", {
            sessionId: sessionId,
            stopwatch: this.state,
          });
        }
      });
    }
  };

  resetStopWatch = () => {
    startTime = null;
    pausedTime = 0;
    elapsedTime = 0;
    const { socket, sessionId, isAccessGranted } = this.props;

    this.setState(
      {
        time: `00:00:000`,
        pointerTransform: `translate(-50%, -100%) rotate(0)`,
        isRunning: false,
        startBtnText: "Start",
      },
      () => {
        if (isAccessGranted) {
          socket.emit("student:stopwatch", {
            sessionId: sessionId,
            stopwatch: this.state,
          });
        }
      }
    );

    this.svgEl.style.transform = "";
    this.svgEllipse.style.strokeDashoffset = "";

    window.cancelRequestAnimFrame(anim);
  };

  render() {
    const { time, pointerTransform, isRunning, startBtnText } = this.state;
    let { closeTool } = this.props;

    let cmarks = [];
    for (let i = 0; i < 60; i++) {
      cmarks.push(<div key={i} className={s["clock__mark"]} />);
    }
    return (
      <ToolHolder name={"Stop Watch"} closeTool={closeTool} tool={"StopWatch"}>
        <div className={s["clock-wrapper"]}>
          <div className={cx(s["clock"], { [s["clock--started"]]: isRunning })}>
            <div className={s["clock__inner-bg"]} />
            <div className={s["clock__inner-bg"]}>
              <div className={s["clock__markers"]}>{cmarks}</div>
            </div>
            <div className={s["clock__center"]} />
            <div className={s["clock__inners"]}>
              <div className={s["clock__inner-center"]} />
              <div className={s["clock__inner-center"]} />
              <div className={s["clock__inner-center"]} />
            </div>
            <div
              className={s["clock__pointer"]}
              style={{ transform: pointerTransform }}
            />
            <svg
              ref={(s) => (this.svgEl = s)}
              className={s["svg"]}
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Created with Method Draw - https://github.com/duopixel/Method-Draw/ */}
              <defs>
                <radialGradient
                  gradientTransform="translate(1,0.5) scale(-0.99,1)"
                  r="1.200581"
                  cy={0}
                  cx={0}
                  spreadMethod="pad"
                  id="svg_9"
                >
                  <stop offset={0} stopOpacity="0.996094" stopColor="#ff415a" />
                  <stop offset={1} stopOpacity={0} stopColor="#252525" />
                </radialGradient>
              </defs>
              <g>
                <title>Layer 1</title>
                <ellipse
                  ref={(e) => (this.svgEllipse = e)}
                  stroke="url(#svg_9)"
                  ry="21.5%"
                  rx="21.5%"
                  id="svg_1"
                  cy="50%"
                  cx="50%"
                  strokeWidth="12%"
                  fill="transparent"
                />
              </g>
            </svg>
          </div>
          <span className={s["clock-time"]}>{time}</span>
          <div className={s["clock-buttons"]}>
            <button
              onClick={this.resetStopWatch}
              type="button"
              className={s["clock-button"]}
              data-role="clock-reset"
            >
              <span className={s["clock-button__text"]}>Reset</span>
            </button>
            <button
              onClick={this.startStopWatch}
              type="button"
              className={cx(s["clock-button"], s["clock-button--start"], {
                [s["clock-button--pause"]]: isRunning,
              })}
              data-role="clock-start-stop"
            >
              <span className={s["clock-button__text"]}>{startBtnText}</span>
            </button>
          </div>
        </div>
      </ToolHolder>
    );
  }
}

export default StopWatch;
