import React, { useState } from "react";
import {
  Row,
  Col,
  Layout,
  Space,
  Popover,
  Popconfirm,
  Menu,
  Button,
  Statistic,
  Modal,
  Typography,
  Tooltip,
} from "antd";
import {
  ExpandOutlined,
  FileImageOutlined,
  FormatPainterOutlined,
  FundProjectionScreenOutlined,
  HighlightOutlined,
  SaveOutlined,
  SelectOutlined,
  CheckOutlined,
  AudioOutlined,
  AudioMutedOutlined,
} from "@ant-design/icons";
import { Link } from "react-router";
import CustomScrollbars from "../../utils/CustomScrollbars";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import Sidebar from "./Sidebar";
import QRCode from "qrcode.react";
import { Tools } from "../drawing";

const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const copyToClipboard = (clipboardText) => {
  let textArea = document.createElement("textarea");

  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = 0;
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.value = clipboardText;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Copying sessionId command was " + msg);
  } catch (err) {
    console.log("Oops, unable to copy");
  }
  document.body.removeChild(textArea);
};

const Topbar = ({
  workbookTitle,
  sessionId,
  syncWithClass,
  endSession,
  saveAnnotation,
  removeSlide,
  toggleSlidePreview,
  preview,
  toggleCollapse,
  slides,
  previewSlide,
  onSortEnd,
  backToHomeSlide,
  navigateSlide,
  selectTool,
  toggleLiveSearch,
  toggleFullScreen,
  toolsPosition,
  pauseStudentScreen,
  togglePdfNameAlert,
  toggleStudentNavigation,
  studentNavigation,
  isDirtySlide,
  isAccessGranted,
  toggleRequestAccess,
  exit,
  isAudioPaused,
  toggleMic,
}) => {
  return (
    <>
      <Header className="gx-text-white" style={{ backgroundColor: "#02a2de" }}>
        <div
          style={{ width: "100%" }}
          className="gx-d-flex gx-justify-content-between"
        >
          <div className="gx-mr-3">
            <Space className="gx-text-white">
              <img alt="lo" src={"images/logo-w.png"} />
              <Space className="gx-text-center" direction="vertical">
                <small>Workbook Name</small>
                <span className="gx-linebar">{workbookTitle}</span>
              </Space>
              <Space
                className="gx-text-center gx-pointer"
                direction="vertical"
                onClick={() => {
                  Modal.info({
                    title: "Lecture Session ID",
                    okText: "Copy & Close",
                    className: "gx-text-center",
                    icon: null,
                    content: (
                      <>
                        <Typography.Title>{sessionId}</Typography.Title>
                        <QRCode
                          value={`https://join2.contribute.cloud?sessionId=${sessionId}`}
                          renderAs={"svg"}
                          size={250}
                          bgColor={"#02a2de"}
                          fgColor={"#ffffff"}
                          level={"L"}
                          includeMargin={true}
                        />
                      </>
                    ),
                    onOk() {
                      copyToClipboard(sessionId);
                    },
                  });
                }}
              >
                <small>Session ID</small>
                <span className="gx-linebar">{sessionId}</span>
              </Space>
            </Space>
          </div>

          <div>
            {!exit ? (
              <>
                <Button
                  className={"gx-mt-1"}
                  size="large"
                  icon={<CheckOutlined />}
                  onClick={toggleRequestAccess}
                >
                  {isAccessGranted
                    ? "Stop Collaborating"
                    : "Request Collaboration Access"}
                </Button>

                {isDirtySlide && (
                  <Button
                    className={"gx-mt-1"}
                    size="large"
                    danger
                    icon={<CheckOutlined />}
                    onClick={syncWithClass}
                  >
                    Not Live, Click to Sync Now
                  </Button>
                )}

                {/* {isAudioPaused ? (
                  <Button
                    className={"gx-mt-1"}
                    size="large"
                    danger
                    icon={<AudioMutedOutlined />}
                    onClick={toggleMic}
                    shape={"circle"}
                  />
                ) : (
                  <Button
                    className={"gx-mt-1"}
                    size="large"
                    icon={<AudioOutlined />}
                    onClick={toggleMic}
                    shape={"circle"}
                  />
                )} */}
              </>
            ) : (
              <Button
                className={"gx-mt-1"}
                size="large"
                danger
                icon={<CheckOutlined />}
              >
                Session ended by host
              </Button>
            )}
          </div>

          <div className="gx-linebar gx-mr-3">
            {studentNavigation && (
              <Space>
                <Tooltip title={"Navigate to first slide"}>
                  <i
                    className="gx-icon-btn icon icon-home"
                    onClick={backToHomeSlide}
                  />
                </Tooltip>
                <Tooltip title={"Previous slide"}>
                  <i
                    className="gx-icon-btn icon icon-chevron-left"
                    onClick={() => navigateSlide("previous")}
                  />
                </Tooltip>
                <Tooltip title={"Next Slide"}>
                  <i
                    className="gx-icon-btn icon icon-chevron-right"
                    onClick={() => navigateSlide("next")}
                  />
                </Tooltip>
                {(toolsPosition === "top" || toolsPosition === "both") && (
                  <>
                    <Tooltip title={"Select"}>
                      <i
                        className="gx-icon-btn icon icon-map-directions"
                        onClick={() => {
                          selectTool(Tools.Select);
                        }}
                      />
                    </Tooltip>

                    <Tooltip title={"Pencil"}>
                      <HighlightOutlined
                        className="gx-icon-btn icon"
                        onClick={() => selectTool(Tools.Pencil)}
                      />
                    </Tooltip>

                    <Popover
                      content={
                        <>
                          <Space size="large">
                            <i
                              onClick={() => selectTool(Tools.Circle)}
                              className="icon icon-circle-o gx-pointer"
                            />
                            <i
                              onClick={() => selectTool(Tools.Rectangle)}
                              className="icon icon-uncheck-squire-o gx-pointer"
                            />
                            <i
                              onClick={() => selectTool(Tools.Arrow)}
                              className="icon icon-long-arrow-right gx-pointer"
                            />
                            <i
                              onClick={() => selectTool(Tools.Line)}
                              className="icon icon-divider gx-pointer"
                            />
                          </Space>
                        </>
                      }
                      title={null}
                      trigger={["click", "hover"]}
                    >
                      <i className="gx-icon-btn icon icon-icon" />
                    </Popover>

                    <Tooltip title={"Text"}>
                      <i
                        onClick={() => selectTool(Tools.IText)}
                        className="gx-icon-btn icon icon-font"
                      />
                    </Tooltip>

                    <Tooltip title={"Online Image Search"}>
                      <FileImageOutlined
                        className="gx-icon-btn icon"
                        onClick={() => toggleLiveSearch()}
                      />
                    </Tooltip>

                    <Tooltip title={"Full Screen"}>
                      <ExpandOutlined
                        className="gx-icon-btn icon"
                        onClick={() => toggleFullScreen()}
                      />
                    </Tooltip>
                  </>
                )}
                <Tooltip title={<FormattedMessage {...messages.saveSlide} />}>
                  <Popconfirm
                    okText="Yes"
                    placement="topRight"
                    cancelText="No"
                    onConfirm={() => saveAnnotation()}
                    title={"Are you sure you want to save annotations?"}
                  >
                    <SaveOutlined className="gx-icon-btn icon" />
                  </Popconfirm>
                </Tooltip>

                {/* <Tooltip title={"Remove Current Slide"}>
                <Popconfirm
                  okText="Yes"
                  placement="topRight"
                  cancelText="No"
                  onConfirm={() => removeSlide()}
                  title={"Are you sure you want to remove current slide?"}
                >
                  <i className="gx-icon-btn icon icon-trash" />
                </Popconfirm>
              </Tooltip> */}
                {/*<Tooltip title={"Sync Now"}>*/}
                {/*  <i*/}
                {/*    onClick={() => syncWithClass()}*/}
                {/*    className="gx-icon-btn icon icon-shuffle"*/}
                {/*    style={{*/}
                {/*      ...(isDirtySlide ? { color: "red", fontSize: 40 } : {}),*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</Tooltip>*/}

                {/* <Tooltip title={"Sync Now"}>
                <i
                  onClick={() => syncWithClass()}
                  className="gx-icon-btn icon icon-shuffle"
                />
              </Tooltip> */}

                {/* <Tooltip title={<FormattedMessage {...messages.endSession} />}>
                <Popconfirm
                  okText="Yes"
                  placement="topRight"
                  cancelText="No"
                  onConfirm={() => endSession()}
                  title={"Are you sure you want to end this session?"}
                >
                  <i className="gx-icon-btn icon icon-signin" />
                </Popconfirm>
              </Tooltip> */}
              </Space>
            )}
          </div>
        </div>
      </Header>

      <Sidebar
        slides={slides}
        visible={preview}
        toggleSlidePreview={toggleSlidePreview}
        removeSlide={removeSlide}
        previewSlide={previewSlide}
        items={slides}
        onSortEnd={onSortEnd}
        pauseStudentScreen={pauseStudentScreen}
        togglePdfNameAlert={togglePdfNameAlert}
        toggleStudentNavigation={toggleStudentNavigation}
      />
    </>
  );
};
export default Topbar;
