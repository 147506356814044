import axios from "axios";
import { SET_USER_WORKBOOK } from "./types";

export function setUserWorkbook(workbook) {
  return { type: SET_USER_WORKBOOK, workbook };
}

export function createWorkbook(workbook) {
  return (dispatch) => axios.post("/api/workbook/new", workbook);
}

export function fetchWorkbooks() {
  return (dispatch) =>
    axios.get("/api/workbook/fetch").then((res) => {
      dispatch(setUserWorkbook(res.data.workbooks));
    });
}

export function updateWorkbook(workbook) {
  return (dispatch) => axios.post("/api/workbook/update", workbook);
}

export function saveWorkbook(workbook) {
  return (dispatch) => axios.post("/api/workbook/saveData", workbook);
}

export function getWorkbook(workbook) {
  return (dispatch) => axios.post("/api/workbook/getWorkbook", workbook);
}

export function deleteLecture(workbookID, userID) {
  return (dispatch) =>
    axios
      .post("/api/workbook/deleteWorkbook", { workbookID, userID })
      .then((res) => {
        dispatch(setUserWorkbook(res.data.workbooks));
      });
}

export function downloadFile() {
  return (dispatch) => axios.post("/api/workbook/downloadFile");
}
export function downloadPDF(data) {
  return (dispatch) => axios.post("/api/workbook/downloadPDF", data);
}
export function addSlideToEnd(data) {
  return (dispatch) => axios.post("/api/workbook/addSlideToEnd", data);
}
export function downloadLecture(id) {
  return (dispatch) => axios.get(`/api/workbook/${id}`);
}

export function addWhiteBoard(data) {
  return (dispatch) => axios.post(`/api/workbook/addWhiteBoard`, data);
}
