import React, { Component } from "react";
import { Button, Radio, Form, Input, message } from "antd";
import { connect } from "react-redux";
import { login } from "../../actions/authActions";
import PropTypes from "prop-types";
import io from "socket.io-client";

import s from "./Login.module.css";
class LoginForm extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      sessionId: "",
      sessionType: "lecture",
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.socket = io(process.env.REACT_APP_API_HOST, {
      transports: ["websocket"],
    });
  }

  componentDidMount = () => {
    let query = this.context.router.location.query;
    if (query.sessionId) {
      this.setState(
        { sessionId: query.sessionId, sessionType: query.type || "lecture" },
        () => {
          this.formRef.current.setFieldsValue({
            email: "",
            sessionId: this.state.sessionId,
            sessionType: this.state.sessionType,
          });
        }
      );
    }

    this.socket.on("connect", () => {
      console.log("connected to server..");
    });

    this.socket.on("databaseError", (data) => {
      if (data.email === this.state.email) {
        message.error("Please Contact Administrator. Something happen wrong.");
        this.setState({ isLoading: false });
      }
    });

    this.socket.on("sucessfullyJoinedToLecture", (data) => {
      if (data.email === this.state.email) {
        this.context.router.push({
          pathname: "/lecture",
          query: { email: data.email, sessionId: data.sessionId },
        });
      }
    });

    this.socket.on("noSessionFound", (data) => {
      if (data.email === this.state.email) {
        this.setState({ isLoading: false });
        message.error("Sorry, Session with this session id doesn't exists.");
      }
    });

    this.socket.on("lectureNotStartedYet", (data) => {
      if (data.email === this.state.email) {
        this.setState({ isLoading: false });
        message.error(
          "Sorry, The Session you want to join is not started yet."
        );
      }
    });
  };

  onSubmit(values) {
    if (values.sessionType === "webinar") {
      this.context.router.push({
        pathname: "/webinar",
        query: { email: values.email, sessionId: values.sessionId },
      });
    } else {
      this.setState(values, () => {
        this.socket.emit("joinRoom", values);
      });
    }
  }

  render() {
    return (
      <div className={s["right-align"]}>
        <div className={s["register-head"]}>
          <h1>Join Your Session</h1>
          <p>Contribute, Collaborate, Cast</p>
        </div>
        <Form
          ref={this.formRef}
          name="joinForm"
          onFinish={this.onSubmit}
          initialValues={{}}
        >
          <Form.Item
            name="email"
            className="gx-pt-5"
            rules={[
              {
                required: true,
                message: "Enter Your Name",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="sessionId"
            rules={[
              {
                required: true,
                message: "Enter Session ID",
              },
            ]}
          >
            <Input size="large" placeholder="Session ID" />
          </Form.Item>

          <Form.Item name="sessionType">
            <Radio.Group>
              <Radio value="lecture">Workbook Session</Radio>
              <Radio value="webinar">Video Session</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit">
              Join Session
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

LoginForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, { login })(LoginForm);
