import React, { Component } from "react";
import OpenViduVideoComponent from "./OvVideo";
import s from "../LecturePage.module.css";
export default class UserVideoComponent extends Component {
  constructor(props) {
    super(props);

    this.handleVideoClicked = this.handleVideoClicked.bind(this);
  }

  getNicknameTag() {
    // Gets the nickName of the user
    return "JSON.parse(this.props.streamManager.stream.connection.data).clientData";
  }

  handleVideoClicked(event) {
    // Triggers event for the parent component to update its main video display (other UserVideoComponent)
    if (this.props.mainVideoStream) {
      this.props.mainVideoStream(this.props.streamManager);
    }
  }

  render() {
    return (
      <figure className={s["at-screenimg"]}>
        {this.props.streamManager !== undefined ? (
          <OpenViduVideoComponent streamManager={this.props.streamManager} />
        ) : null}
      </figure>
    );
  }
}
