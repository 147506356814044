import axios from "axios";
import { HOST } from "../config";
export function getUserWorkbookList() {
  return (dispatch) => axios.get("/api/workbook");
}

export function getWorkbookDetails(id) {
  return (dispatch) => axios.get(`/api/workbook/${id}`);
}
export function getSessionId() {
  return (dispatch) => axios.get(`/api/workbook/createSessionId`);
}

export function deleteUserWorkbook(workbookId) {
  return (dispatch) => axios.delete(`/api/workbook/${workbookId}`);
}

export function exportUserWorkbook(workbookId) {
  return (dispatch) => axios.get(`${HOST}/api/workbook/${workbookId}/export`);
}

export function updateUserWorkbook(workbookId, data) {
  return (dispatch) => axios.put(`/api/workbook/${workbookId}`, data);
}

export function startLectureAndInviteStudent(workbookId, data) {
  return (dispatch) => axios.post(`/api/workbook/${workbookId}/invite`, data);
}

export function isfileExists(path) {
  return (dispatch) => axios.post(`/api/workbook/isfileExists`, { path });
}

export function getDocDetails(path) {
  return (dispatch) => axios.post(`${HOST}/api/workbook/docDetails`, { path });
}

export function saveAnnotation(data) {
  return (dispatch) => axios.post(`/api/workbook/saveAnnotation`, data);
}
