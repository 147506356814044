import React, { Component } from "react";
import PropTypes from "prop-types";
import FlashMessagesList from "./flash/FlashMessagesList";

import { connect } from "react-redux";
import {
  addFlashMessage,
  deleteFlashMessage,
} from "../actions/flashMessages.js";

import { UnAuthLayout } from "./layout/index";

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // const { isAuthenticated } = this.props.auth;
    // if (isAuthenticated) {
    //   this.context.router.push('/dashboard');
    // }
  }

  render() {
    const { pathname } = location;
    if (
      pathname &&
      (pathname === "/" ||
        pathname.indexOf("signup") > -1 ||
        pathname.indexOf("reset") > -1)
    ) {
      return (
        <div>
          <FlashMessagesList />
          <UnAuthLayout>{this.props.children}</UnAuthLayout>
        </div>
      );
    } else if (pathname && pathname.indexOf("lecture") > -1) {
      return (
        <div>
          <FlashMessagesList />
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div style={{ height: "100%" }}>
          <FlashMessagesList />
          {this.props.children}
        </div>
      );
    }
  }
}

App.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

App.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  addFlashMessage,
  deleteFlashMessage,
})(App);

// export default App;
