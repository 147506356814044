import axios from "axios";
import { HOST } from "../config";

export function getToken(data) {
  return (dispatch) => axios.post(`${HOST}/api/openvidu/join`, data);
}

export function getSessions(data) {
  return (dispatch) => axios.post("/api/openvidu/sessions", data);
}

export function getSession(data) {
  return (dispatch) => axios.post("/api/openvidu", data);
}

export function destroySession(data) {
  return (dispatch) => axios.post("/api/openvidu/destroySession", data);
}
