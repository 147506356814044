import React, { Component } from "react";
import PropTypes from "prop-types";
import ToolHolder from "../holder/ToolHolder";
import cx from "classnames";

import s from "./PeriodicTable.module.css";

class PeriodicTable extends Component {
  static propTypes = {
    closeTool: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount = () => {};

  render() {
    let { closeTool } = this.props;

    return (
      <ToolHolder
        name={"Periodic Table"}
        classname={"at-priodictable"}
        closeTool={closeTool}
        tool={"PeriodicTable"}
      >
        <div className={s["wrapper"]}>
          <ul className={s["main"]}>
            {/* ROW 1 */}
            <li
              data-pos={1}
              data-nb="1.0079"
              className={cx(s["type-1"], s["cat-2"])}
            >
              H<span>Hydrogen</span>
            </li>
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li
              data-pos={2}
              data-nb="4.0026"
              className={cx(s["type-2"], s["cat-2"])}
            >
              He<span>Helium</span>
            </li>
            {/* ROW 2 */}
            <li
              data-pos={3}
              data-nb="6.941"
              className={cx(s["type-3"], s["cat-0"])}
            >
              Li<span>Lithium</span>
            </li>
            <li
              data-pos={4}
              data-nb="9.0122"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Be<span>Beryllium</span>
            </li>
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li
              data-pos={5}
              data-nb="10.811"
              className={cx(s["type-5"], s["cat-0"])}
            >
              B<span>Boron</span>
            </li>
            <li
              data-pos={6}
              data-nb="12.011"
              className={cx(s["type-5"], s["cat-0"])}
            >
              C<span>Carbon</span>
            </li>
            <li
              data-pos={7}
              data-nb="14.007"
              className={cx(s["type-5"], s["cat-2"])}
            >
              N<span>Nitrogen</span>
            </li>
            <li
              data-pos={8}
              data-nb="15.999"
              className={cx(s["type-5"], s["cat-2"])}
            >
              O<span>Oxygen</span>
            </li>
            <li
              data-pos={9}
              data-nb="18.998"
              className={cx(s["type-5"], s["cat-2"])}
            >
              F<span>Fluorine</span>
            </li>
            <li
              data-pos={10}
              data-nb="20.180"
              className={cx(s["type-2"], s["cat-2"])}
            >
              Ne<span>Neon</span>
            </li>
            {/* ROW 3 */}
            <li
              data-pos={11}
              data-nb="22.990"
              className={cx(s["type-3"], s["cat-0"])}
            >
              Na<span>Sodium</span>
            </li>
            <li
              data-pos={12}
              data-nb="24.305"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Mg<span>Magnesium</span>
            </li>
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li className={s["empty"]} />
            <li
              data-pos={13}
              data-nb="26.982"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Al<span>Aluminium</span>
            </li>
            <li
              data-pos={14}
              data-nb="28.086"
              className={cx(s["type-5"], s["cat-0"])}
            >
              Si<span>Silicon</span>
            </li>
            <li
              data-pos={15}
              data-nb="30.974"
              className={cx(s["type-5"], s["cat-0"])}
            >
              P<span>Phosphorus</span>
            </li>
            <li
              data-pos={16}
              data-nb="32.065"
              className={cx(s["type-5"], s["cat-0"])}
            >
              S<span>Sulfur</span>
            </li>
            <li
              data-pos={17}
              data-nb="35.453"
              className={cx(s["type-5"], s["cat-2"])}
            >
              Cl<span>Chlorine</span>
            </li>
            <li
              data-pos={18}
              data-nb="39.948"
              className={cx(s["type-2"], s["cat-2"])}
            >
              Ar<span>Argon</span>
            </li>
            {/* ROW 4 */}
            <li
              data-pos={19}
              data-nb="39.098"
              className={cx(s["type-3"], s["cat-0"])}
            >
              K<span>Potassium</span>
            </li>
            <li
              data-pos={20}
              data-nb="40.078"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Ca<span>Calcium</span>
            </li>
            <li
              data-pos={21}
              data-nb="44.956"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Sc<span>Scandium</span>
            </li>
            <li
              data-pos={22}
              data-nb="47.867"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ti<span>Titanium</span>
            </li>
            <li
              data-pos={23}
              data-nb="50.942"
              className={cx(s["type-6"], s["cat-0"])}
            >
              V<span>Vanadium</span>
            </li>
            <li
              data-pos={24}
              data-nb="51.996"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Cr<span>Chromium</span>
            </li>
            <li
              data-pos={25}
              data-nb="54.938"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Mn<span>Manganese</span>
            </li>
            <li
              data-pos={26}
              data-nb="55.845"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Fe<span>Iron</span>
            </li>
            <li
              data-pos={27}
              data-nb="58.933"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Co<span>Cobalt</span>
            </li>
            <li
              data-pos={28}
              data-nb="58.693"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ni<span>Nickel</span>
            </li>
            <li
              data-pos={29}
              data-nb="63.546"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Cu<span>Copper</span>
            </li>
            <li
              data-pos={30}
              data-nb="65.39"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Zn<span>Zinc</span>
            </li>
            <li
              data-pos={31}
              data-nb="69.723"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Ga<span>Gallium</span>
            </li>
            <li
              data-pos={32}
              data-nb="72.64"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Ge<span>Germanium</span>
            </li>
            <li
              data-pos={33}
              data-nb="74.922"
              className={cx(s["type-5"], s["cat-0"])}
            >
              As<span>Arsenic</span>
            </li>
            <li
              data-pos={34}
              data-nb="78.96"
              className={cx(s["type-5"], s["cat-0"])}
            >
              Se<span>Selenium</span>
            </li>
            <li
              data-pos={35}
              data-nb="79.904"
              className={cx(s["type-5"], s["cat-1"])}
            >
              Br<span>Bromine</span>
            </li>
            <li
              data-pos={36}
              data-nb="83.80"
              className={cx(s["type-2"], s["cat-2"])}
            >
              Kr<span>Krypton</span>
            </li>
            {/* ROW 5 */}
            <li
              data-pos={37}
              data-nb="85.468"
              className={cx(s["type-3"], s["cat-0"])}
            >
              Rb<span>Rubidium</span>
            </li>
            <li
              data-pos={38}
              data-nb="87.62"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Sr<span>Strontium</span>
            </li>
            <li
              data-pos={39}
              data-nb="88.906"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Y<span>Yttrium</span>
            </li>
            <li
              data-pos={40}
              data-nb="91.224"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Zr<span>Zirconium</span>
            </li>
            <li
              data-pos={41}
              data-nb="92.906"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Nb<span>Niobium</span>
            </li>
            <li
              data-pos={42}
              data-nb="95.94"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Mo<span>Molybdenum</span>
            </li>
            <li
              data-pos={43}
              data-nb="(96)"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Tc<span>Technetium</span>
            </li>
            <li
              data-pos={44}
              data-nb="101.07"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ru<span>Ruthenium</span>
            </li>
            <li
              data-pos={45}
              data-nb="102.91"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Rh<span>Rhodium</span>
            </li>
            <li
              data-pos={46}
              data-nb="106.42"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Pd<span>Palladium</span>
            </li>
            <li
              data-pos={47}
              data-nb="107.87"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ag<span>Silver</span>
            </li>
            <li
              data-pos={48}
              data-nb="112.41"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Cd<span>Cadmium</span>
            </li>
            <li
              data-pos={49}
              data-nb="114.82"
              className={cx(s["type-7"], s["cat-0"])}
            >
              In<span>Indium</span>
            </li>
            <li
              data-pos={50}
              data-nb="118.71"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Sn<span>Tin</span>
            </li>
            <li
              data-pos={51}
              data-nb="121.76"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Sb<span>Antimony</span>
            </li>
            <li
              data-pos={52}
              data-nb="127.60"
              className={cx(s["type-5"], s["cat-0"])}
            >
              Te<span>Tellurium</span>
            </li>
            <li
              data-pos={53}
              data-nb="126.90"
              className={cx(s["type-5"], s["cat-0"])}
            >
              I<span>Iodine</span>
            </li>
            <li
              data-pos={54}
              data-nb="131.29"
              className={cx(s["type-2"], s["cat-2"])}
            >
              Xe<span>Xenon</span>
            </li>
            {/* ROW 6 */}
            <li
              data-pos={55}
              data-nb="132.91"
              className={cx(s["type-3"], s["cat-0"])}
            >
              Cs<span>Caesium</span>
            </li>
            <li
              data-pos={56}
              data-nb="137.33"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Ba<span>Barium</span>
            </li>
            <li data-pos="57-71" className={s["type-8"]}>
              La-Lu<span>Lanthanide</span>
            </li>
            <li
              data-pos={72}
              data-nb="178.49"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Hf<span>Hafnium</span>
            </li>
            <li
              data-pos={73}
              data-nb="180.95"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ta<span>Tantalum</span>
            </li>
            <li
              data-pos={74}
              data-nb="183.64"
              className={cx(s["type-6"], s["cat-0"])}
            >
              W<span>Tungsten</span>
            </li>
            <li
              data-pos={75}
              data-nb="186.21"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Re<span>Rhenium</span>
            </li>
            <li
              data-pos={76}
              data-nb="190.23"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Os<span>Osmium</span>
            </li>
            <li
              data-pos={77}
              data-nb="192.22"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Ir<span>Iridium</span>
            </li>
            <li
              data-pos={78}
              data-nb="195.08"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Pt<span>Platinum</span>
            </li>
            <li
              data-pos={79}
              data-nb="196.97"
              className={cx(s["type-6"], s["cat-0"])}
            >
              Au<span>Gold</span>
            </li>
            <li
              data-pos={80}
              data-nb="200.59"
              className={cx(s["type-7"], s["cat-1"])}
            >
              Hg<span>Mercury</span>
            </li>
            <li
              data-pos={81}
              data-nb="204.38"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Tl<span>Thallium</span>
            </li>
            <li
              data-pos={82}
              data-nb="207.2"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Pb<span>Lead</span>
            </li>
            <li
              data-pos={83}
              data-nb="208.96"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Bi<span>Bismuth</span>
            </li>
            <li
              data-pos={84}
              data-nb="(209)"
              className={cx(s["type-7"], s["cat-0"])}
            >
              Po<span>Polonium</span>
            </li>
            <li
              data-pos={85}
              data-nb="(210)"
              className={cx(s["type-5"], s["cat-0"])}
            >
              At<span>Astatine</span>
            </li>
            <li
              data-pos={86}
              data-nb="(222)"
              className={cx(s["type-2"], s["cat-2"])}
            >
              Rn<span>Radon</span>
            </li>
            {/* ROW 7 */}
            <li
              data-pos={87}
              data-nb="(223)"
              className={cx(s["type-3"], s["cat-0"])}
            >
              Fr<span>Francium</span>
            </li>
            <li
              data-pos={88}
              data-nb="(226)"
              className={cx(s["type-4"], s["cat-0"])}
            >
              Ra<span>Radium</span>
            </li>
            <li data-pos="89-103" className={s["type-9"]}>
              Ac-Lr<span>Actinide</span>
            </li>
            <li
              data-pos={104}
              data-nb="(261)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Rf<span>Rutherfodum</span>
            </li>
            <li
              data-pos={105}
              data-nb="(262)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Db<span>Dubnium</span>
            </li>
            <li
              data-pos={106}
              data-nb="(266)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Sg<span>Seaborgium</span>
            </li>
            <li
              data-pos={107}
              data-nb="(264)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Bh<span>Bohrium</span>
            </li>
            <li
              data-pos={108}
              data-nb="(277)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Hs<span>Hassium</span>
            </li>
            <li
              data-pos={109}
              data-nb="(268)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Mt<span>Meitnerium</span>
            </li>
            <li
              data-pos={110}
              data-nb="(281)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Ds<span>Damstadium</span>
            </li>
            <li
              data-pos={111}
              data-nb="(272)"
              className={cx(s["type-6"], s["cat-3"])}
            >
              Rg<span>Roentgenium</span>
            </li>
            <li
              data-pos={112}
              data-nb="(285)"
              className={cx(s["type-7"], s["cat-3"])}
            >
              Uub<span>Ununbium</span>
            </li>
            <li
              data-pos={113}
              data-nb="(285)"
              className={cx(s["type-7"], s["cat-3"])}
            >
              Uut<span>Ununtrium</span>
            </li>
            <li
              data-pos={114}
              data-nb="(289)"
              className={cx(s["type-7"], s["cat-3"])}
            >
              Uuq<span>Ununquadium</span>
            </li>
            <li
              data-pos={115}
              data-nb="(289)"
              className={cx(s["type-7"], s["cat-3"])}
            >
              Uup<span>Ununpentium</span>
            </li>
            <li
              data-pos={115}
              data-nb="(289)"
              className={cx(s["type-7"], s["cat-3"])}
            >
              Uuh<span>Ununhexium</span>
            </li>
            <li
              data-pos={115}
              data-nb="(289)"
              className={cx(s["type-0"], s["cat-3"])}
            >
              Uus<span>Ununseptum</span>
            </li>
            <li
              data-pos={115}
              data-nb="(289)"
              className={cx(s["type-2"], s["cat-3"])}
            >
              Uuo<span>Ununoctium</span>
            </li>
          </ul>
          <div className={s["legend"]}>
            <ul className={s["list-1"]}>
              <li className={s["cat-0"]}>
                <span>C</span>Solid
              </li>
              <li className={s["cat-1"]}>
                <span>Hg</span>Liquid
              </li>
              <li className={s["cat-2"]}>
                <span>H</span>Gas
              </li>
              <li className={s["cat-3"]}>
                <span>Rf</span>Unknown
              </li>
            </ul>
            <ul className={s["list-2"]}>
              <li className={s["type-3"]}>Alkadi metals</li>
              <li className={s["type-8"]}>Lanthanoids</li>
              <li className={s["type-9"]}>Actinoids</li>
              <li className={s["type-7"]}>Poor metals</li>
              <li className={s["type-2"]}>Noble gases</li>
              <li className={s["type-6"]}>Transition metals</li>
              <li className={s["type-5"]}>Other non-metals</li>
              <li className={s["type-4"]}>Alkadine earth metals</li>
            </ul>
          </div>
        </div>
      </ToolHolder>
    );
  }
}

export default PeriodicTable;
