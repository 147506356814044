import React, { Component } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import cx from "classnames";

import s from "./ToolHolder.module.css";

class ToolHolder extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    classname: PropTypes.string,
    tool: PropTypes.string.isRequired,

    closeTool: PropTypes.func.isRequired,
  };
  static defaultProps = {
    name: "Untitled Tool",
  };
  constructor(props) {
    super(props);
  }

  render() {
    const { name, classname, closeTool, tool } = this.props;

    return (
      <Draggable handle={`.${s["at-watchhead"]}`}>
        <div className={cx(s["at-holder"], s[classname])}>
          <div className={s["at-watchhead"]}>
            <h2>{name}</h2>
            <a
              onClick={closeTool.bind(this, tool)}
              className={s["at-btnclosetool"]}
            >
              <i className="fa fa-times"></i>
            </a>
          </div>

          <div className={s["at-stopwatchcontent"]}>{this.props.children}</div>
        </div>
      </Draggable>
    );
  }
}

export default ToolHolder;
