import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import s from "./LecturePage.module.css";
import cx from "classnames";
import map from "lodash/map";
import UserVideoComponent from "./OpenVidu/UserVideoComponent";

function LectureTopBar({
  name,
  subject,
  toggleRequestAccess,
  restartLecture,
  requestFromStudent,
  isAccessGranted,
  studentList,
  manageAccess,
  sessionId,
  toggleMic,
  subscribers,
  isAudioPaused,
  compareArray,
  mannageCompare,
  toggleComparePopup,
  comparePopupFlag,
  toggleSessionIdPopup,
  exit,
  isDirtySlide,
  syncWithClass,
}) {
  return (
    <div className={s["at-topbar"]}>
      <div className={s["container-fluid"]}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className={s["at-toprightarea"]}>
              <strong className={s["at-logo"]}>
                <a href="javascript:void(0);">
                  <img src="images/logo-b.png" />
                </a>
              </strong>
              <a className={s["at-btnback"]} href="javascript:void(0);">
                <span>
                  <b>Name:</b> {name}
                </span>{" "}
                <br />
                {/* <small>Subject: {subject}</small> <br /> */}
                <span onClick={toggleSessionIdPopup}>
                  <b>Session ID:</b> {sessionId}
                </span>
              </a>
            </div>

            <ul className={s["at-notificationsbtns"]}>
              <li onClick={toggleMic}>
                <a
                  className={cx(s["mic-btn"], {
                    [s["mic-unmute"]]: isAudioPaused,
                  })}
                >
                  <i className="fa fa-microphone" />
                </a>
              </li>
            </ul>

            <div className={s["at-btnholder"]}>
              {!exit ? (
                <Fragment>
                  <a
                    onClick={toggleRequestAccess}
                    className={cx(s["at-btnlacture"], {
                      [s["nsg-success"]]: isAccessGranted,
                    })}
                    href="javascript:void(0)"
                  >
                    <i className="fa fa-check-circle" />
                    <span>
                      {isAccessGranted
                        ? "Stop Collaborating"
                        : "Request Collaboration Access"}
                    </span>
                  </a>

                  {isDirtySlide && (
                    <a
                      onClick={syncWithClass}
                      className={cx(s["at-btnlacture"], {
                        [s["mic-unmute"]]: isDirtySlide,
                      })}
                      href="javascript:void(0)"
                    >
                      <i className="fa fa-check-circle" />
                      <span>Not Live, Click to Sync Now</span>
                    </a>
                  )}
                </Fragment>
              ) : (
                <a className={s["at-btnback"]} href="javascript:void(0);">
                  <span>
                    <b>session ended by host</b>
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LectureTopBar.propTypes = {
  name: PropTypes.string.isRequired,
  compareArray: PropTypes.array.isRequired,
  subscribers: PropTypes.array.isRequired,
  sessionId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  toggleRequestAccess: PropTypes.func.isRequired,
  isAccessGranted: PropTypes.bool.isRequired,
  toggleComparePopup: PropTypes.func.isRequired,
  mannageCompare: PropTypes.func.isRequired,
  restartLecture: PropTypes.func.isRequired,
  comparePopupFlag: PropTypes.bool.isRequired,
  toggleSessionIdPopup: PropTypes.func.isRequired,
  exit: PropTypes.bool.isRequired,
};
export default LectureTopBar;
