import {
  IMAGE_TYPE,
  LAYOUT_TYPE,
  SPREADSHEET_TYPE,
  TEXT_TYPE,
  VIDEO_TYPE,
  DATA_TYPE,
  NOTEBOOK_TYPE,
} from "./types";

function getExtention(name) {
  console.log(name);
  return name
    .slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1)
    .toUpperCase();
}
export function categorizeMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };
  media.forEach((obj) => {
    const name = obj.name;
    const record = {};
    record.drive = "dropbox";
    record.downloadLinkType = "link";
    record.downloadLink = obj.link;
    record.thumbnail = record.downloadLink; // images/file-type-excel.png
    record.name = obj.name;

    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg"; // images/file-type-excel.png
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-02.jpg";
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  });
  return category;
}
export function categorizeGoogleMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    GOOGLE_SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    GOOGLE_TEXT: [],
    DATA: [],
    GOOGLE_DATA: [],
    NOTEBOOK: [],
  };
  media.forEach((obj) => {
    const name = obj.name;
    obj.icon = obj.iconUrl;
    const record = {};
    record.drive = "google";
    record.id = obj.id;
    const token = window.gapi.auth.getToken();
    record.token = token.access_token;
    record.downloadLink = `https://drive.google.com/uc?export=download&id=${obj.id}`;
    record.thumbnail = `https://drive.google.com/uc?export=view&id=${obj.id}`;
    record.name = obj.name;
    record.downloadLinkType = "link";
    record.mimeType = obj.mimeType;
    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg";
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (obj.serviceId == "spread") {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      record.name = `${record.name}.xlsx`;
      category.GOOGLE_SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg";
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (TEXT_TYPE.indexOf(obj.serviceId.toUpperCase()) > -1) {
      record.thumbnail = "/images/thumbnails/img-02.jpg";
      record.downloadFileType = `word`;
      record.name = `${record.name}.${obj.serviceId}`;
      category.GOOGLE_TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (obj.serviceId == "pres") {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      record.name = `${record.name}.ppt`;
      category.GOOGLE_DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  });
  return category;
}
export function categorizeOneMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };
  media.forEach((obj) => {
    const name = obj.name;
    const record = {};
    record.drive = "one";
    record.thumbnail = obj.thumbnails
      ? obj.thumbnails[0]
        ? obj.thumbnails[0].large.url
        : ""
      : "";
    record.downloadLink = obj["@microsoft.graph.downloadUrl"];
    record.downloadLinkType = "link";
    record.name = name;
    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg"; // images/file-type-excel.png
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-02.jpg";
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  });
  return category;
}

export function categorizeLocalMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };
  const promiseArray = [];
  media.forEach((obj) => {
    const p = new Promise((resolve, reject) => {
      let reader = new FileReader();
      const name = obj.name;
      const record = {};
      record.drive = "local";
      record.thumbnail = obj.preview;
      record.downloadLinkType = "base64";
      record.name = name;
      reader.addEventListener("load", () => {
        record.downloadLink = reader.result;
        resolve();
      });
      if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
        record.downloadFileType = `image`;
        category.IMAGE.push(record);
      } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-07.jpg";
        record.downloadFileType = `pdf`;
        category.LAYOUT.push(record);
      } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-05.jpg";
        record.downloadFileType = `excel`;
        category.SPREADSHEET.push(record);
      } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-06.jpg";
        record.downloadFileType = `video`;
        category.VIDEO.push(record);
      } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-02.jpg";
        record.downloadFileType = `word`;
        category.TEXT.push(record);
      } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-03.jpg";
        record.downloadFileType = `ppt`;
        category.DATA.push(record);
      } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-04.jpg";
        record.downloadFileType = `notebook`;
        category.NOTEBOOK.push(record);
      }

      reader.readAsDataURL(obj);
    });

    promiseArray.push(p);
  });

  return Promise.all(promiseArray).then((res, rej) => {
    return category;
  });
}

export function ValidURL(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return regex.test(str);
}
