import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
class Webinar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomId: "",
      username: "",
    };
  }

  componentDidMount = () => {
    let query = this.context.router.location.query;
    if (!query.sessionId || !query.email) {
      this.context.router.push({
        pathname: "/",
      });
    } else {
      this.setState({ roomId: query.sessionId, username: query.email });
    }
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        {isMobile ? (
          <h1>
            For webinars we are only supporting desktop. Please try to connect
            from your computer.
          </h1>
        ) : (
          <iframe
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allow="camera; microphone"
            src={`${process.env.REACT_APP_MEET_HOST}/${this.state.roomId}?roomId=${this.state.roomId}&username=${this.state.username}&type=webinar`}
          />
        )}
      </div>
    );
  }
}

Webinar.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Webinar;
