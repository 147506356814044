export default {
  Circle: "circle",
  Arrow: "arrow",
  Pencil: "pencil",
  Rectangle: "rectangle",
  Select: "select",
  Pan: "pan",
  IText: "itext",
  Erase: "erase",
  Line: "line",
};
