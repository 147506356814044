import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import map from "lodash/map";
import Draggable from "react-draggable";
import blackListTerm from "../../data/blackListTerms";
import s from "./LecturePage.module.css";

class GallerySearch extends Component {
  static propTypes = {
    addToSlide: PropTypes.func.isRequired,
    toggleLiveSearch: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchedImage: [],
      errors: {},
      query: "",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (!this.state.query)
      return this.setState({
        errors: { query: "Please Provide the image qurey you want to search" },
        searchedImage: [],
      });

    if (blackListTerm.indexOf(this.state.query) > -1)
      return this.setState({
        errors: {
          query: "Search term blacklisted. Please search for a different image",
        },
        searchedImage: [],
      });

    this.setState({ errors: {}, searchedImage: [] });

    let pixbayLoopCount = 4;

    try {
      for (let i = 1; i <= pixbayLoopCount; i++) {
        let pixbayResponse = await fetch(
          `https://pixabay.com/api/?key=4911746-4de4624647c39fc418c5d907e&image_type=photo&q=${this.state.query}&safesearch=true&per_page=125&page=${i}`
        );
        let pixbayResult = await pixbayResponse.text();

        let pixbayImages = JSON.parse(pixbayResult);

        const pixbayDataArray = map(pixbayImages.hits, (val) => {
          return { srcUrl: val.webformatURL, previewURL: val.previewURL };
        });

        this.setState({
          searchedImage: [...this.state.searchedImage, ...pixbayDataArray],
        });

        if (Math.ceil(pixbayImages.total / 125) < 4) {
          pixbayLoopCount = Math.ceil(pixbayImages.total / 125);
        }
      }

      for (let i = 1; i <= 2; i++) {
        let pexelsResponse = await fetch(
          `https://api.pexels.com/v1/search?query=${this.state.query}&per_page=40&page=${i}&safesearch=true`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Bearer 563492ad6f9170000100000145aa5098102d41456eee7660e7fe21df",
            },
          }
        );

        let pexelsResult = await pexelsResponse.text();

        let pexelsImages = JSON.parse(pexelsResult);
        const pexelsDataArray = map(pexelsImages.photos, (val) => {
          return { srcUrl: val.src.large, previewURL: val.src.small };
        });

        this.setState({
          searchedImage: [...this.state.searchedImage, ...pexelsDataArray],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    let { errors, query } = this.state;
    let { isOpen, addToSlide, toggleLiveSearch } = this.props;
    const options = map(this.state.searchedImage, (val, key) => (
      <li key={key}>
        <figure className={s["at-liveimg"]}>
          <img src={val.previewURL} alt={val.tags} key={key} />
          <figcaption
            onClick={addToSlide.bind(this, val.srcUrl)}
            className={s["at-addimage"]}
          >
            <i className="fa fa-plus"></i>
            <span>Add Image</span>
          </figcaption>
        </figure>
      </li>
    ));

    return (
      <Draggable handle={`.${s["gallery-search"]}`}>
        <div
          className={cx(s["at-imagesearchoholder"], {
            [s["at-openlivesearch"]]: isOpen,
          })}
        >
          <form onSubmit={this.onSubmit}>
            <fieldset>
              <a
                onClick={toggleLiveSearch}
                className={s["at-btnclosesearchimg"]}
              >
                <i className="fa fa-times" />
              </a>
              <a className={cx(s["at-btndragsearchimg"], s["gallery-search"])}>
                <i className="fa fa-bars" />
              </a>
              <div className={s["at-searchholder"]}>
                <button type="submit" className="fa fa-search"></button>
                <input
                  onChange={this.onChange}
                  value={query}
                  name="query"
                  type="search"
                  className={s["at-searchholder"]}
                  placeholder="Live Search & enter"
                />
                {errors && <span>{errors.query}</span>}
              </div>
            </fieldset>
          </form>

          <ul className={s["at-searchimages"]}>{options}</ul>
        </div>
      </Draggable>
    );
  }
}

export default GallerySearch;
