import React from "react";
import { Route, IndexRoute } from "react-router";

import App from "./components/App";
import LoginPage from "./components/login/LoginPage";
import LecturePage from "./components/lecture/LecturePage";
import WebinarPage from "./components/webinar";

export default (
  <Route path="/" component={App}>
    <IndexRoute component={LoginPage} />
    <Route path="lecture" component={LecturePage} />
    <Route path="webinar" component={WebinarPage} />
  </Route>
);
