import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import s from "./FlashMessage.css";

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.deleteFlashMessage(this.props.message.id);
  }

  render() {
    const { id, type, text } = this.props.message;

    setTimeout(() => {
      this.props.deleteFlashMessage(id);
    }, 5000);

    return (
      <div
        className={classnames(s["alert-msg"], {
          [s["at-success"]]: type === "success",
          [s["at-danger"]]: type === "error",
        })}
      >
        <span>{text}</span>
      </div>
    );
  }
}

FlashMessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired,
};

export default FlashMessage;
