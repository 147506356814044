import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import map from "lodash/map";
import ToolHolder from "../holder/ToolHolder";
import { searchWord, thesaurusSearch } from "../../../../actions/toolsActions";
import TextFieldGroup from "../../../common/TextFieldGroup/TextFieldGroup";
import s from "./Dictionary.module.css";
import { HOST } from "../../../../config";
class Dictionary extends Component {
  static propTypes = {
    closeTool: PropTypes.func.isRequired,
    searchWord: PropTypes.func.isRequired,
    thesaurusSearch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchedResult: [],
      type: "Dictionary",
      errors: {},
      query: "",
    };
  }
  componentDidMount = () => {
    const { socket, sessionId, isAccessGranted } = this.props;

    socket.on("teacher:dictionary", (data) => {
      this.setState(data.dictionary);
    });
  };

  onChange = (e) => {
    const { socket, sessionId, isAccessGranted } = this.props;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (isAccessGranted) {
        socket.emit("student:dictionary", {
          sessionId: sessionId,
          dictionary: this.state,
        });
      }
    });
  };
  onChangeType = (type) => {
    const { socket, sessionId, isAccessGranted } = this.props;
    this.setState({ type }, () => {
      if (isAccessGranted) {
        socket.emit("student:dictionary", {
          sessionId: sessionId,
          dictionary: this.state,
        });
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { searchWord, thesaurusSearch, isAccessGranted } = this.props;
    let { type } = this.state;
    const { socket, sessionId } = this.props;

    if (!this.state.query)
      return this.setState(
        {
          errors: { query: "Please Provide the word you want to search" },
          searchedResult: [],
        },
        () => {
          if (isAccessGranted) {
            socket.emit("student:dictionary", {
              sessionId: sessionId,
              dictionary: this.state,
            });
          }
        }
      );

    axios(`${HOST}/api/tools/dictionary?define=${this.state.query}`)
      .then(({ data }) => {
        console.log("data", data);
        this.setState({ searchedResult: data }, () => {
          if (isAccessGranted) {
            socket.emit("student:dictionary", {
              sessionId: sessionId,
              dictionary: this.state,
            });
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errors: {
            query:
              "Make sure you entered correct data. We are not able to process this.",
          },
        });
      });
  };
  render() {
    let { closeTool } = this.props;
    let { errors, query, type } = this.state;

    return (
      <ToolHolder
        name={"Dictionary"}
        classname={"at-tallyholder"}
        closeTool={closeTool}
        tool={"Dictionary"}
      >
        <div className={s["at-dictionarycontent"]}>
          <div style={{ margin: 0, padding: 0 }} className="row">
            <div className={s["at-dictionarysearch"]}>
              <form className={s["at-wordsearcform"]} onSubmit={this.onSubmit}>
                {errors.form && (
                  <div className={cx(s["at-alerterror"], "alert alert-danger")}>
                    {errors.form}
                  </div>
                )}

                <fieldset>
                  <TextFieldGroup
                    error={errors.query}
                    label={`${type} search ... `}
                    onChange={this.onChange}
                    value={query}
                    field="query"
                  />
                </fieldset>
                <fieldset>
                  <button className={"at-themebtn pull-right"} type="submit">
                    Search Word
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
          <div className="row" style={{ margin: 0, padding: "30px 0 0 15px" }}>
            <p
              style={{ height: 270, overflow: "auto", position: "absolute" }}
              dangerouslySetInnerHTML={{ __html: this.state.searchedResult }}
            ></p>
          </div>
        </div>
      </ToolHolder>
    );
  }
}

export default connect(null, { searchWord, thesaurusSearch })(Dictionary);
