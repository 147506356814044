import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import { decodeTokenSetCurrentUser } from "../../actions/authActions";
import { addFlashMessage } from "../../actions/flashMessages";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgetPassModal: false,
    };
  }
  componentDidMount = () => {
    let { query } = this.props.location;

    const { success, token, type, error } = query;

    if (type == "social") {
      if (success === "true") {
        let parsedToken = JSON.parse(token);

        this.props.decodeTokenSetCurrentUser(parsedToken.token);
        this.context.router.push({ pathname: "dashboard" });
      } else {
        this.props.addFlashMessage({
          type: "error",
          text:
            error ||
            "Something went wrong while login. Try again in few minutes.",
        });
        this.context.router.push({ pathname: "/" });
      }
    }
  };

  onToggle = () => {
    this.setState({ showForgetPassModal: !this.state.showForgetPassModal });
  };

  render() {
    return (
      <div>
        <LoginForm toggleForgetPassModal={this.onToggle} />
      </div>
    );
  }
}

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

LoginPage.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  decodeTokenSetCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, {
  addFlashMessage,
  decodeTokenSetCurrentUser,
})(LoginPage);

// export default LoginPage
